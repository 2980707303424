<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="cneter-box pt--100" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="立即下单"
                        title="立即下单"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
        </div>
        <div class="cneter-box pt--40 pb--60" style="background-color: #fff;">
            <div class="w80 plr-10">
                <!-- <div class="flex-start1">
                    <div class="title-icon"></div>
                    <div class="name-top">发送留言</div>
                </div> -->
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">产品名称：</div>
                    </div>
                    <div class="form-input">
                        <input type="text" placeholder="请选择">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">收货人：</div>
                    </div>
                    <div class="form-input">
                        <input type="text" placeholder="请填写真实姓名">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">联系电话：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">详细地址：</div>
                    </div>
                    <div class="form-input">
                      <input type="text">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">付款金额 ：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">付款时间：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">结算方式：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">开发票：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">输入开票信息：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1 w200">
                        <div class="dop"></div>
                        <div class="form-name">上传开票信息：</div>
                    </div>
                    <div class="form-input">
                        <input type="textarea">
                    </div>
                </div>
                
                <div class="flex-cneter mt--120">
                    <div class="commit-btn btn-text flex-cneter">
                        确认信息，并提交订单
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    export default {
        name: 'About',
        components: {SectionTitle,Layout},
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>
<style scoped>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #F44336 !important;
    color: #fff !important;
}
.nav-pills .nav-link {
    cursor: pointer;
    color: #000 !important;
    margin-right: 40px;
}
.text-tip {
    font-size: 32px;
    font-family: 'iFontszhounianti';
    font-weight: 400;
    color: #E10000;
}
.text-bt-tip {
    margin: 40px 0;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #000000;
}
</style>