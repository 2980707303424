<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-8 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li>
                                <router-link to="/about">
                                <span>关于我们</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/contact">
                                    <span>联系我们</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/contact">
                                    <span>@奥瑞数控科技有限公司</span>
                               </router-link>
                            </li>
                            <li>
                                <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#000" target="_blank" rel="noopener noreferrer">皖ICP备11003314号-9</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text">© Doob {{ new Date().getFullYear() }}</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>
<style scoped>
.router-link-exact-active {
    color: #E60012 !important;
}
.router-link-exact-active:hover {
    color: #E60012 !important;
}
ul li a:hover {
    color: #E60012 !important;
}
</style>