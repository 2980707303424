import request from '@/utils/request'

export function getccDateKanBan(companyId,deviceId) {
    return request({
        url: 'http://114.116.123.94:8080/screen/ccDateKanBan?companyId='+companyId+'&'+'deviceId='+deviceId,
        method: 'get',
        headers : {
            isToken : false
        }
    })
}

export function getccDateKanBanList(companyId,deviceId,taskName,begin,end,pageNum,pageSize) {
    return request({
        url: 'http://114.116.123.94:8080/screen/ccDateKanBanList?companyId='+companyId+'&deviceId='+deviceId+'&taskName='+taskName
        +'&begin='+begin+'&end='+end+'&pageNum='+pageNum+'&pageSize='+pageSize,
        method: 'get',
        headers : {
            isToken : false
        }
    })
}