<template>
<Layout :header-transparency="true" :show-top-header="false"  :footer-style="6">
    <div class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
        <div class="container" style="margin-top: 10%;text-align: center;">
            网站建设中,敬请期待!
        </div>
    </div>
</Layout>
</template>
<script>
    import Layout from '../../components/common/Layout'
    export default {
        name: 'test',
        components: {Layout}
    }

</script>
