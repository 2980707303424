<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <div class="containermax mb--20 flex-start2" style="background: #FFFFFF;">
                <div :class="tabs.length>6?'goods-width50 d-flex justify-content-center':'goods-width50 d-flex'">
                    <div  v-for="(item,index) in tabs" :key="index" @click="clickTabs(item.name)">
                        <div :class="item.check?'text-18 cursor-pointer':'text-18 cursor-pointer c-000'" style="margin-right:20px">
                        {{item.name}}
                        <div class="current" v-if="item.check"></div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle v-if="iconName"
                            text-align="center"
                            :subtitle="iconName"
                            :title="iconName"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15 bg goods-width70" v-if="goodsList[0]"  @click="toDetail(goodsList[0].id)">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 pt--20 pb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp" >
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img :src="imgUpload+ goodsList[0].mainImage" style="height: 300px;">
                            </div>
                            <div>
                                <div class="goodsName">{{goodsList[0].name}}</div>
                                <div class="goodName-des w400">
                                    <span v-for="(item,index) in goodsList[0].sellingPointList" :key='index'>
                                        {{item}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{goodsList[0].price}}</div>
                                <div class="d-flex justify-content-end">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" style="position: absolute;bottom: 18%;" @click="toDetail(goodsList[0].id)">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row row--15 bg goods-width70 mt--20"  v-if="goodsList[1]" @click="toDetail(goodsList[1].id)">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 pt--20 pb--20"  data-aos="slide-up" data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div>
                                <div class="goodsName">{{goodsList[1].name}}</div>
                                <div class="goodName-des">
                                    <span v-for="(item,index) in goodsList[1].sellingPointList" :key='index'>
                                        {{item}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{goodsList[1].price}}</div>
                                <div class="d-flex btn-box-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" style="position: absolute;bottom: 18%;" @click="toDetail(goodsList[1].id)">了解更多</div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center aling-item-center mt--10" style="width: 400px;">
                                <img :src="imgUpload+ goodsList[1].mainImage" style="height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row row--15  goods-width70 mt--20 flex-warp d-flex justify-content-between" v-if="goodsList.length>1" >
                    <div class="bg w50 pt--20 pb--20 mb--20"  v-for="(item,index) in goodsList" :key='index' v-if="index>1" @click="toDetail(item.id)"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp" >
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img :src="imgUpload+item.mainImage" style="height:300px">
                            </div>
                            <div class="mt--20">
                                <div class="goodsName" style="min-width: 200px;">{{item.name}}</div>
                                <div class="goodName-des">
                                    <span v-for="(citem,cindex) in item.sellingPointList" :key='cindex'>
                                        {{citem}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{item.price}}</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail(item.id)">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row row--15  goods-width70 mt--20 flex-warp">
                    <div class="bg w50 pt--20 pb--20 mb--20"  v-if="goodsList[2]"  @click="toDetail(goodsList[2].id)"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img :src="imgUpload+ goodsList[2].mainImage" style="height: 300px;">
                            </div>
                            <div class="pl--10 pr--10">
                                <div class="goodsName">{{goodsList[2].name}}</div>
                                <div class="goodName-des">
                                    <span v-for="(item,index) in goodsList[2].sellingPointList" :key='index'>
                                        {{item}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{goodsList[2].price}}</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail(goodsList[2].id)">了解更多</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:2%"></div>
                    <div class="bg w50 pt--20 pb--20 mb--20"  v-if="goodsList[3]" @click="toDetail(goodsList[3].id)"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                 <img :src="imgUpload+ goodsList[3].mainImage" style="height: 300px;">
                            </div>
                            <div class="pl--10 pr--10"> 
                                <div class="goodsName">{{goodsList[3].name}}</div>
                                <div class="goodName-des">
                                    <span v-for="(item,index) in goodsList[3].sellingPointList" :key='index'>
                                        {{item}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{goodsList[3].price}}</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail(goodsList[3].id)">了解更多</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                 <!-- <el-carousel height="530px" indicator-position='outside' v-if="goodsList.length>4">
                    <el-carousel-item v-for="item in 2" :key="item">
                        <div class="row row--15 goods-width70 grid3 mt--10" v-if="item == 1">
                            <div class="bg  pt--20 pb--20 mb--20" v-for="(item,index) in goodsList" :key='index' v-if="index>3"  @click="toDetail(item.id)"
                                data-aos="slide-up"
                                data-aos-duration="800">
                                <div class="d-flex justify-content-around aling-item-center flex-warp">
                                    <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                        <img :src="imgUpload+ item.mainImage" alt=" " style="height: 300px;">
                                    </div>
                                    <div>
                                        <div class="goodsName text-center">{{item.name}}</div>
                                        <div class="goodName-des text-center" style="height:50px">
                                            <span v-for="(citem,cindex) in item.sellingPointList" :key='cindex'>
                                                {{citem}}
                                            </span>
                                        </div>
                                        <div class="goods-price mb--20 text-center">￥{{item.price}}</div>
                                        <div class="d-flex justify-content-center">
                                            <div class="goods-btn-black margin-right20 btn-font-balck"  @click="toDetail(item.id)">了解更多</div>
                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row--15 goods-width70 grid3 mt--10" v-if="item ==2 && goodsList.length>6">
                            <div class="bg  pt--20 pb--20 mb--20" v-for="(item,index) in goodsList" :key='index' v-if="index>6"  @click="toDetail(item.id)"
                                data-aos="slide-up"
                                data-aos-duration="800">
                                <div class="d-flex justify-content-around aling-item-center flex-warp">
                                    <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                        <img :src="imgUpload+ item.mainImage" alt=" " style="height: 300px;">
                                    </div>
                                    <div>
                                        <div class="goodsName text-center">{{item.name}}</div>
                                        <div class="goodName-des text-center" style="height:50px">
                                            <span v-for="(citem,cindex) in item.sellingPointList" :key='cindex'>
                                                {{citem}}
                                            </span>
                                        </div>
                                        <div class="goods-price mb--20 text-center">￥{{item.price}}</div>
                                        <div class="d-flex justify-content-center">
                                            <div class="goods-btn-black margin-right20 btn-font-balck"  @click="toDetail(item.id)">了解更多</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </el-carousel-item>
            </el-carousel> -->
                </div>
            </div>
    </Layout>
</template>

<script>
    import Layout from "../components/common/Layout";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    export default {
        name: 'hardwave',
        components: {SectionTitle, Layout},
        data() {
            return {
                className: '',
                total:0,
                goodsName: '',
                queryParams1: {
                    pageNum: 1,
                    pageSize: 9999,
                },
                queryParams: {
                    pageNum: 1,
                    pageSize: 10
                },
                goodsList: [],
                iconName: '',
                tabs:[{
                    name:'绘图唛架机',
                    check: true,

                }, {
                    name:'绘图切割机',
                    check: false,
                },{
                    name:'平板模板机',
                    check: false,
                },{
                    name:'铺布裁剪机',
                    check: false,
                },{
                    name:'充绒充棉机',
                    check: false,
                },{
                    name:'吊挂推箱流水线',
                    check: false,
                },{
                    name:'口罩机',
                    check: false,
                }
                ],
      
            }
        },
        watch: {
            $route: {
            handler: function(val, oldVal){
                this.changeRoute(val)
            },
            // 深度观察监听
            deep: true
        }
        },
        mounted(){
            this.changeRoute()
        },
        methods: {
            changeRoute() {
                this.iconName = ''
                this.tabs = []
                if(this.$route.query.name =='智能硬件') {
                    this.className = '智能硬件'
                    this.getclass()
                } else if(this.$route.query.name =='智能软件') {
                    this.className = '智能软件'
                    this.getclass()
                }
                if(this.$route.query.searchName) {
                    this.goodsName = this.$route.query.searchName
                    // this.iconName = this.$route.query.searchName
                    this.getList()
                }
                if(this.$route.query.tabName) {
                    this.iconName = this.$route.query.tabName
                    this.getclass()
                }
            },
            async getclass () {
                const res = await this.$http.get('/siteWeb/goodsType/list?species='+this.className+'&isShow='+2+'&pageNum='+this.queryParams1.pageNum+'&pageSize='+this.queryParams1.pageSize)
                let list = res.data.rows
                this.tabs = []
                if(list&& list.length>0) {
                    list.forEach((ee,index)=>{
                        let pp = {}
                        pp.check = false
                        if(!this.iconName) {
                            if(index == 0) {
                                pp.check = true
                            } else {
                                pp.check = false
                            }
                            this.iconName = res.data.rows[0].type
                        }
                        pp.name = ee.type
                        this.tabs.push(pp)
                        if(this.$route.query.tabName) {
                            this.iconName = this.$route.query.tabName
                            this.tabs.forEach(ee=> {
                                if(ee.name == this.$route.query.tabName) {
                                    ee.check = true
                                } else {
                                    ee.check = false
                                }
                            })
                        }
                    })
                }
                this.getList()
            },
            async getList () {
                const res = await this.$http.get('/siteWeb/goodsInfo/list?oneType='+this.className+'&isShelf='+2+'&twoType='+this.iconName+'&pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize+'&name='+this.goodsName)
                this.goodsList = res.data.rows
                if(this.goodsList && this.goodsList.length>0) {
                    this.goodsList.forEach(ee=> {
                        if(ee.sellingPoint) {
                            ee.sellingPointList = ee.sellingPoint.split(',') // 买点
                        }
                    })
                }
                this.total = res.data.total
            },
            handleSizeChange(val) {
                this.queryParams.pageSize = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.queryParams.pageNum = val
                this.getList()
            },
            clickTabs(name) {
                this.iconName = name
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
                this.getList()
            },
            toDetail(id,item) {
               this.$router.push({name: 'softwareDeatil', query: {id: id}})
            },
            toBuy() {
               this.$router.push({name: 'buyOrder'})
            }
            
        }
    }
</script>
<style scoped>
.grid3 {
    display: grid;
    grid-template-columns: repeat(3, 290px);
    grid-gap: 20px;
}
.bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.flex1 {
    display: flex;
    align-items: center;
}
</style>