<template>
    <Layout :footerStyle='2' :style="{'background-image': `url(${require(`@/assets/images/bg/login.png`)})`,'background-size': '100% 100%','height': '100%'}">
       <!-- class="rwt-contact-area rn-section-gap" -->
        <div  class="demo-slider-area slider-area bg-transparent slider-style-1 pt--40">
            <div class="container">
                <div class="row mt--40">
                    <div class="col-lg-8"></div>
                    <div class="col-lg-4 mt_md--30 mt_sm--30" style="background: #fff;padding:40px 40px;border-radius: 5px;width:400px;height:600px">
                       <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
        <!-- <Separator/> -->
       <div class="rbt-separator-mid">
            <div class="container">
                <hr class="rbt-separator m-0" style="border-top: 1px solid #787872;">
            </div>
        </div>
    </Layout>
</template>

<script>
    import Separator from "../../components/elements/separator/Separator";
    import Layout from '../../components/common/Layout'
    import Breadcrumb from '../../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Icon from "../../components/icon/Icon";
    import GoogleMap from "../../components/elements/contact/GoogleMap";
    import ContactForm from "../../components/elements/contact/ContactForm-c";

    export default {
        name: 'ContactPage',
        components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout,Separator}
    }
</script>
<style scoped>
hr {
    border-top: 1px solid #787872 !important;
}
</style>