<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" v-if="topImgList.length>0">
            <VueSlickCarousel v-bind="sliderSetting" v-if="topImgList.length>0">
                <div v-for="(slider, index) in topImgList" :key="index" class="single-slide">
                    <div class="height-850  bg_image"
                         :style="{'background-image': `url(${imgUpload+`${slider.image}`})`}">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-lg-12">
                                    <div class="inner">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description" style="color:#fff" v-html="slider.description"/>
                                        <div class="button-group mt--30" v-if="slider.url">
                                            <a class="btn-default btn-large round"
                                               :href="slider.url" target="_blank">
                                               了解更多
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div> -->

        <div class="blog-area rn-section-gap1">
            <el-carousel height="700px" indicator-position='' trigger="click" interval='5000'>
                <el-carousel-item  v-for="(slider, index) in topImgList" :key="index">
                    <img :src="imgUpload+ slider.image" alt="" style="width:100%;height:100%">
                </el-carousel-item>
            </el-carousel>
        </div>
        

        <div class="blog-area mt--30" v-if="hotGoodsList.length>0">
            <div class="container">
                <div class="row" >
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="热门商品"
                            title="热门商品"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15 bg goods-width90" v-if="hotGoodsList.length>0" @click="toBuy(hotGoodsList[0].id)">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 pt--20 pb--20" 
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 460px;">
                                <img :src="imgUpload+ hotGoodsList[0].mainImage" style="height:300px">
                            </div>
                            <div>
                                <div class="goodsName">{{hotGoodsList[0].name}}</div>
                                <div class="goodName-des flex-warp w400">
                                    <span v-for="(item,index) in hotGoodsList[0].sellingPointList" :key='index'>
                                        {{item}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{hotGoodsList[0].price}}</div>
                                <div class="d-flex justify-content-end">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" style="position: absolute;bottom: 18%;">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="row row--15  goods-width90 mt--20 flex-warp d-flex justify-content-between" v-if="hotGoodsList.length>1" >
                    <div class="bg w50 pt--20 pb--20 mb--20"  v-for="(item,index) in hotGoodsList" :key='index' v-if="index>0" @click="toBuy(item.id)"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp" >
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img :src="imgUpload+item.mainImage" style="height:300px">
                            </div>
                            <div class="mt--20">
                                <div class="goodsName" style="min-width: 200px;">{{item.name}}</div>
                                <div class="goodName-des">
                                    <span v-for="(citem,cindex) in item.sellingPointList" :key='cindex'>
                                        {{citem}}
                                    </span>
                                </div>
                                <div class="goods-price mb--20">￥{{item.price}}</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toBuy(item.id)">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div style="width:2%"></div> -->
                    <!-- <div class="bg w50 pt--60 pb--60 mb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/hty3.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName">服装大师88型连供喷墨绘图仪</div>
                                <div class="goodName-des w400">采用惠普通用 HP88 连续供墨喷头，可多次加注墨水,更省耗材;分辨率高,速度均匀,线条更清晰;最高速度可达每小时160平方。</div>
                                <div class="goods-price mb--20">￥13800</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toBuy">了解更多</div>
                                    <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="flex1 height-750" :style="{'background-image': `url(${imgUpload+image})`,'width':'100%','background-size': '100% 100%'}" v-if="image">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-12">
                        <div class="inner">
                            <!-- <h1 class="title">星链工厂</h1>
                            <p class="description" style="color:#fff">数据与科技</p> -->
                            <div class="button-group mt--30" v-if="url">
                                <a class="btn-default btn-large round"
                                    :href="url" target="_blank">
                                    了解更多
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="rwt-elements-area rn-section-gap">
            <div class="container-fluid plr--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Portfolio"
                            title="Our Completed Project!"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Portfolio :portfolio-data="portfolioData" column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"/>
            </div>
        </div> -->
        <!-- End Service Area -->

        <div class="rwt-brand-area rn-section-gapBottom mt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="典型客户"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <el-carousel height="360px" indicator-position='outside'>
                    <el-carousel-item v-for="item in 2" :key="item">
                       <div class="row" v-if="item == 1">
                            <div class="col-lg-12 mt--40">
                                <Brand :brand-list="brandList"/>
                            </div>
                        </div>
                        <div class="row" v-if="item == 2">
                            <div class="col-lg-12 mt--40">
                                <Brand :brand-list="brandList2"/>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Brand from "../../components/elements/brand/Brand";
    import Layout from "../../components/common/Layout";
    import Separator from "../../components/elements/separator/Separator";
    import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
    import VueSlickCarousel from "vue-slick-carousel";
    export default {
        name: 'Startup',
        components: {SectionTitle, Brand,Separator, Layout, VueSlickCarousel},
        data() {
            return {
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-09.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-10.png`)
                    },
                ],
                brandList2: [
                    {
                        image: require(`@/assets/images/brand/brand-11.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-12.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-13.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-14.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-15.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-16.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-17.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-18.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-19.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-20.png`)
                    },
                ],
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    autoplay:true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'index',
                        title: `Auri-tech45喷墨绘图机1111111`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                    {
                        image: 'index',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    }
                ],
 
                topImgList: [],
                bottomImgList: [],
                image: '',
                url: '',
                hotGoodsList: [], // 热门商品
            }
        },
        created() {
            this.getData()
            this.getHotGoodsList()
        },
        methods: {
            toBuy(id) {
                this.$router.push({name: 'softwareDeatil', query: {id: id}})
            },
            getData(){
                this.$http.get('/siteWeb/ads/list?pageNum='+1+'&pageSize='+9999+"&status=0").then(res=>{
                    var list = res.data.rows
                    this.topImgList = []
                    this.bottomImgList = []
                    if(list && list.length>0) {
                        list.forEach(ee=> {
                            ee.image = ee.content
                            if(ee.addres == '首页顶部') {
                                this.topImgList.push(ee)
                            } else if(ee.addres == '首页底部') {
                                this.bottomImgList.push(ee)
                                this.image = this.bottomImgList[0].image
                                this.url = this.bottomImgList[0].url
                            }
                        })
                    }
                })
            },
            getHotGoodsList(){
                this.$http.get('/siteWeb/goodsInfo/list?pageNum='+1+'&pageSize='+9999+"&isHot=2"+"&isShelf=2").then(res=>{
                    this.hotGoodsList = res.data.rows
                    if(this.hotGoodsList && this.hotGoodsList.length>0) {
                        this.hotGoodsList.forEach(ee=> {
                            if(ee.sellingPoint) {
                                ee.sellingPointList = ee.sellingPoint.split(',') // 买点
                            }
                        })
                    }
                })
            },
            
        }
    }
</script>
<style scoped>
.bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.flex1 {
    display: flex;
    align-items: center;
}
.rn-section-gap1 {
    padding: 120px 0 0 0;
}
</style>