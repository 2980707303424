<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row pt--40">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="服务政策"
                            title="服务政策"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15 goods-width70">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 pt--40 pb--40">
                        <div class="d-flex justify-content-start aling-item-center flex-warp" >
                            <div class="d-flex  aling-item-center mr--20 w400">
                                <img src="@/assets/images/demo/ser.png" alt="">
                            </div>
                            <div class="w400">
                                <div class="flex-start1">
                                    <div class="dop1" style="margin-left: 0px;"></div>
                                    <div class="goodsName">售后服务联系方式</div>
                                </div>
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">硬件售后服务专线：</div>
                                    <div class="mi--10">0551-65663619</div>
                                </div>
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">硬件售后服务微信：</div>
                                    <div class="mi--10">15156993945</div>
                                </div>
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">软件技术服务专线：</div>
                                    <div class="mi--10">0551-65370898</div>
                                </div>
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">软件技术服务QQ：</div>
                                    <div class="mi--10">834092441</div>
                                </div>
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">配件专线：</div>
                                    <div class="mi--10">18155175002</div>
                                </div>
                                <!-- <div class="flex-start1 mt--5">
                                    <div class="font-size16">传真号码：</div>
                                    <div class="mi--10">0551-65655852</div>
                                </div>
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">企业邮箱：</div>
                                    <div class="mi--10">sales@cad361.com</div>
                                </div> -->
                                <div class="flex-start1 mt--5">
                                    <div class="font-size16">投诉电话：</div>
                                    <div class="mi--10">18855176148 （7x24小时）</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row--15  goods-width70">
                    <img src="@/assets/images/demo/ser2.png" alt="">
                </div>
                <div class="row row--15 mt--40 goods-width70">
                    <div class="goodsName flex-center">奥瑞科技能为您提供哪些服务?</div>
                    <div class="flex-center mt--10 mb--20">
                        <div class="h-line"></div>
                    </div>
                    <div class="flex-center mt--10">
                        <div  v-for="(item,index) in tabs" :key="index" @click="clickTabs(item.name)"
                        :class="item.check?'box-tab-btn flex-center c-fff font-size16 mr--40':'box-tab-btn2 flex-center c-000 font-size16 mr--40'">{{item.name}}</div>
                    </div>

                    <div v-if="iconName=='售前服务'">
                        <div class="flex-start1 mt--40">
                            <div class="dop1"></div>
                            <div class="font-size16">资料：奥瑞科技将免费为用户和签约合作伙伴提供产品技术手册、行业解决方案。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">咨询：签约合作伙伴、用户，在奥瑞科技产品应用中有关技术问题可通过网络/电话/传真/电邮的形式求助，奥瑞科技服务人员将会及时给予回复。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">培训：为用户、签约合作伙伴提供技术培训（讲座）。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">设计：对复杂工程项目的投标或跟进，奥瑞科技为用户、签约合作伙伴在方案设计方面给予全力支持。</div>
                        </div>
                    </div>

                    <div v-if="iconName=='现场服务'">
                        <div class="flex-start1 mt--40">
                            <div class="dop1"></div>
                            <div class="font-size16">现场勘查：针对整厂智能化改造项目，为用户、签约合作伙伴提供现场勘查服务。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">现场安装指导：为用户、签约合作伙伴提供现场安装指导服务。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">工程施工：根据用户要求，我们还提供从方案设计、现场施工、巡检维护等一揽子系统的工程服务。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">现场培训：为用户、签约合作伙伴提供现场运行维护及应急情况的基本培训。</div>
                        </div>
                    </div>

                    <div v-if="iconName=='售后服务'">
                        <div class="flex-start1 mt--40">
                            <div class="dop1"></div>
                            <div class="font-size16">产品保修、保换：对从我司正规渠道购买的产品，均享有一年免费保修、终身技术维护的五星级服务。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">应急响应：提供7*24小时服务热线，在第一时间为您提供各种服务。</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">工程巡检：对由奥瑞科技公司直接提供的整厂智能化改造项目，在产品质保期内，我们会提供免费的年度巡检服务</div>
                        </div>

                        <div class="flex-start1 mt--10">
                            <div class="dop1"></div>
                            <div class="font-size16">过保维护 ：对超出保修期外的奥瑞科技产品提供终身服务，公司承诺只收取成本费。</div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Brand from "../components/elements/brand/Brand";
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    import BlogPost from "../components/blog/BlogPost";
    import BlogPostMixin from "../mixins/BlogPostMixin";
    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../components/elements/portfolio/Portfolio";
    import Progressbar from "../components/elements/progressbar/Progressbar";
    import Timeline from "../components/elements/timeline/Timeline";
    import ServiceFour from "../components/elements/service/ServiceFour";

    export default {
        name: 'Startup',
        components: {
            ServiceFour,
            Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Brand,Separator, Layout, VueSlickCarousel},
        mixins: [BlogPostMixin],
        data() {
            return {
                iconName: '售前服务',
                tabs:[{
                    name:'售前服务',
                    check: true,

                }, {
                    name:'现场服务',
                    check: false,
                },{
                    name:'售后服务',
                    check: false,
                }
                ],
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    }
                ],
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'index',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                    {
                        image: 'index',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    }
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue.js Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ],
                progressData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ]
            }
        },
        methods: {
            clickTabs(name) {
                this.iconName = name
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
            },
            toDetail() {
                var item = {
                    id: '123'
                }
                this.$router.push({name: 'softwareDeatil', param: {id: item.id}})
            },
            toBuy() {
               this.$router.push({name: 'buyOrder'})
            }
            
        }
    }
</script>
<style scoped>
.bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.flex1 {
    display: flex;
    align-items: center;
}
</style>