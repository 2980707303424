<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="cneter-box pt--20" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="兑换商品"
                        title="兑换商品"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
        </div>
        <div class="cneter-box pb--60" style="background-color: #fff;">
           <div class="w80 plr-10 mt--10">
               <div class="flex-between cursor" style="color:#000"  v-if="!haveLogin">
                    <div @click="showlogin">登录</div>
                    <div @click="showTip" style="color:rgb(230, 0, 18)">如何获取积分?</div>
                </div>
                <div class="flex-between" style="color:#000"  v-if="haveLogin">
                    <div>账号：{{loginUser.userNumber}}
                        <span style="color:rgb(230, 0, 18)" @click="companyDetail">企业信息</span>
                        <span class="ml--40 cursor" style="color:rgb(230, 0, 18)" @click="loginout">退出</span>
                    </div>
                    <div>
                        <span>我的积分：{{loginUser.points}}</span>
                        <span class="ml--20 cursor" @click="showDetail">积分明细</span>
                        <span class="ml--20 cursor" @click="showExchange">积分兑换</span>
                        <span class="ml--20 cursor" @click="showTip" style="color:rgb(230, 0, 18)">如何获取积分?</span>
                    </div>
                </div>
            </div>
            <div class="w80 plr-10 mt--10" v-for="(item,index) in news" :key="index">
               <div class="row mt--10 pt--15 pb--15" style="border: 1px solid #E5EFF2;box-shadow: 0px 2px 5px 0px rgb(153 157 159 / 15%);
                    border-radius: 5px;">
                    <div class="col-lg-3 text-center flex-center">
                        <img :src="imgUrl+item.photo" alt="" style="height: 140px;width:200px;border-radius: 4px;">
                    </div>
                    <div class="col-lg-7">
                        <div class="new-title">{{item.name}}</div>
                        <div class="new-des pdlr-10">
                            {{item.intro}}
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="new-title">{{item.points}}</div>
                        <div class="new-des pdlr-10 mt--40">
                          <div class="btn-c flex-center" v-if="haveLogin" @click="openExchange(item)">兑换</div>
                        </div>
                    </div>
               </div>
            </div>
            <div class="flex-center mt--40">
                <el-pagination class="pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="queryParams.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>

            <el-dialog title="登录" :visible.sync="dialogFormVisible"  width="28%" style="margin-top:15vh"
                :show-close='false' center :close-on-click-modal="false">
                <el-form :model="form" ref="form" :rules="rules" label-width="80px">
                    <el-form-item label="账号:" prop="userName" >
                        <el-input v-model="form.userName" style="width:85%;" ></el-input>
                    </el-form-item>
                    <el-form-item label="密码:"  prop="password">
                        <el-input v-model="form.password" type="password" style="width:85%" ></el-input>
                    </el-form-item>
                </el-form>
                <div>
                    <input type="checkbox" id="agree" v-model="agree" required />
                    <label for="agree">我同意<a href="/user-agreement" target="_blank">用户协议</a></label>
<!--                    <label for="agree">我同意-->
<!--                        <router-link to="/user-agreement">用户协议</router-link>-->
<!--                    </label>-->
<!--                    <label for="agree">我同意-->
<!--                        <button ref="button" @click="showComponent">用户协议</button>-->
<!--                    </label>-->
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="login">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog title="积分明细" :visible.sync="dialogTableVisible" center width="40%">
                <div style="height: 480px;overflow-y: auto;">
                    <div class="row mb--5 pt--10 pb--10 ml--5 mr--5"
                         v-for="(item,index) in pointsList" :key="index"
                         style="border: 1px solid #E5EFF2;box-shadow: 0px 2px 5px 0px rgb(153 157 159 / 15%);">
                        <div class="col-lg-3  flex-center">{{item.taskName}}</div>
                        <div class="col-lg-3  flex-center">
                            <p v-if="item.getPoints > 0">+{{item.getPoints}}</p>
                            <p v-else>{{item.getPoints}}</p>
                        </div>
                        <div class="col-lg-3  flex-center">{{item.createTime}}</div>
                        <div class="col-lg-3  flex-center">{{item.allPoints}}</div>
                    </div>
                </div>
            </el-dialog>

            <el-dialog title="企业信息" :visible.sync="companyInfo" center width="40%">
                <div style="height: 480px;overflow-y: auto;">
                    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                        <el-form-item label="账号:" prop="companyName">
                            {{loginUser.userNumber}}
                        </el-form-item>
                        <el-form-item label="企业名称:" prop="companyName">
                            <el-input v-model="form.companyName" placeholder="请输入企业名称" />
                        </el-form-item>
                        <el-form-item label="擅长品类:" prop="adeptCategory">
                            <el-select
                                    v-model="form.adeptCategory"
                                    placeholder="请选擅长品类"
                                    style="width: 145px"
                            >
                                <el-option
                                        v-for="item in proficientDict"
                                        size="small"
                                        :key="item.dictCode"
                                        :label="item.dictLabel"
                                        :value="item.dictLabel"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系方式:" prop="companyPhone" :maxlength="11" >
                            <el-input v-model="form.companyPhone" placeholder="请输入联系方式" />
                        </el-form-item>
                        <el-form-item label="企业规模:" prop="scale">
                            <el-select
                                    v-model="form.scale"
                                    placeholder="请选擅长品类"
                                    style="width: 145px"
                            >
                                <el-option
                                        v-for="item in scaleDict"
                                        size="small"
                                        :key="item.dictCode"
                                        :label="item.dictLabel"
                                        :value="item.dictLabel"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="地区" >
                            <el-select
                                    v-model="selectProvince"
                                    placeholder="请选择省"
                                    style="width: 145px"
                            >
                                <el-option
                                        v-for="item in provinces"
                                        size="small"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        @click.native="changeProvince()"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                    v-model="selectCity"
                                    placeholder="请选择市"
                                    style="width: 145px"
                            >
                                <el-option
                                        v-for="item in cities"
                                        size="small"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        @click.native="changeCity"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                    v-model="selectArea"
                                    placeholder="请选择区"
                                    style="width: 145px"
                            >
                                <el-option
                                        v-for="item in area"
                                        size="small"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="详细地址:" prop="addresDetail">
                            <el-input v-model="form.addresDetail" placeholder="请输入详细地址" />
                        </el-form-item>
                    </el-form>
                    <div>
                        <input type="checkbox" id="agree" v-model="agree" required />
                        <label for="agree">企业信息便于工厂获取更多优质订单,我已阅读并同意<a href="/user-agreement" target="_blank">隐私条款与用户协议</a></label>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="companySave">保 存</el-button>
                        <el-button @click="cancel">取 消</el-button>
                    </div>
                </div>
            </el-dialog>

            <el-dialog title="兑换记录" :visible.sync="exchange" center width="40%" >
                <div style="height: 480px;overflow-y: auto;">
                    <div class="row mb--5 pt--10 pb--10 ml--5 mr--5" v-for="(item,index) in exchangeList" :key="index"
                    style="border: 1px solid #E5EFF2;box-shadow: 0px 2px 5px 0px rgb(153 157 159 / 15%);">
                        <div class="col-lg-10  flex-start">
                            <div class="flex-start">
                                <img :src="imgUrl+item.goodsPhoto" alt="" style="height: 80px;width:100px;border-radius: 4px;">
                                <div class="ml--20">
                                    <div style="font-size:15px;font-weight: 600;">{{item.goodsName}}</div>
                                    <div class="flex-between">
                                        <div>申请兑换时间：{{item.createTime.substring(0,10)}}</div>
                                        <div class="ml--40" v-if="item.name && item.goodsType =='1'">姓名：{{item.name}}</div>
                                    </div>
                                    <div class="flex-between">
                                        <div v-if="item.phone && item.goodsType =='1'">联系方式：{{item.phone}}</div>
                                        <div class="ml--40" v-if="item.address && item.goodsType =='1'">地址：{{item.address}}</div>
                                    </div>
                                     <div class="flex-between">
                                        <div v-if="item.updateTime && item.status =='2'">核销时间：{{item.updateTime}}</div>
                                        <div v-if="item.updateTime && item.status =='1'">取消时间：{{item.updateTime}}</div>
                                        <div class="ml--40" v-if='item.address && item.goodsType =="3"'>账号：{{item.address}}</div>
                                    </div>
                                    <div v-if="item.remark">备注：{{item.remark}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2  flex-end" style="font-size:15px;font-weight: 600;color:#67c23a;" v-if="item.status=='2'">已完成</div>
                        <div class="col-lg-2  flex-end" style="font-size:15px;font-weight: 600;color:#e6a23c" v-if="item.status=='0'">待核销</div>
                        <div class="col-lg-2  flex-end" style="font-size:15px;font-weight: 600;color:#E60012" v-if="item.status == '1'">已取消</div>
                    </div>
                </div>
            </el-dialog>

            <el-dialog title="确认兑换" :visible.sync="addModel"  width="28%" style="margin-top:15vh"
                :show-close='false' center :close-on-click-modal="false">
                <el-form :model="form" ref="form" :rules="rules" label-width="100px">
                    <el-form-item label="">
                        <div>您要兑换的是： <span style="color:#E60012">{{form.goodsName}}</span></div>
                    
                    </el-form-item>
                    <el-form-item label="姓名:"  prop="name">
                        <el-input v-model="form.name" style="width:85%;" ></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式:" prop="phone">
                        <el-input v-model="form.phone" style="width:85%" :maxlength="11" ></el-input>
                    </el-form-item>
                    <el-form-item label="地址:" prop="address">
                        <el-input v-model="form.address" style="width:85%" ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addModel = false">取 消</el-button>
                    <el-button type="primary" @click="save">确 定</el-button>
                </div>
            </el-dialog>


            <el-dialog title="确认兑换" :visible.sync="addRenewModel"  width="28%" style="margin-top:15vh"
                :show-close='false' center :close-on-click-modal="false">
                <el-form :model="form" ref="form" :rules="rules" label-width="100px">
                    <el-form-item>
                        <div>您要兑换的是： <span style="color:#E60012">{{form.goodsName}}</span></div>
                    </el-form-item>
                    <el-form-item label="账号:"  prop="address">
                        <el-input v-model="form.address" style="width:85%;" placeholder="注：请确认账号，确认后将无法修改！" ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addRenewModel = false">取 消</el-button>
                    <el-button type="primary" @click="saveRenew">确 定</el-button>
                </div>
            </el-dialog>
        </div>

    </Layout>
</template>

<script>

    import indexApi from '@/api/exchange'
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import { regionData, codeToText} from "../../node_modules/element-china-area-data";
    export default {
        name: 'About',
        components: {SectionTitle,Layout},
        data() {
            return {
                //省市区联动下拉框
                provinces: regionData,
                cities: [],
                area: [],
                proficientDict: "",
                scaleDict: "",
                selectProvince: "",
                selectCity: "",
                selectArea: "",

                addRenewModel:false,
                // imgUrl:'http://192.168.31.132', // 本地图片访问地址
                // imgUrl:'http://47.100.109.76:99', // 测试图片访问地址
                imgUrl:'http://61.191.198.49:99', // 正式图片访问地址
                pointsList:[],
                exchangeList:[],
                loginUser:{},
                agree:null,
                addModel:false,
                exchange:false,
                // 表单校验
                rules: {
                    /**登录参数 */
                    userName: [
                        { required: true, message: "账号不能为空", trigger: "blur" }
                    ],
                    password: [
                        { required: true, message: "密码不能为空", trigger: "blur" }
                    ],
                    name:[
                        { required: true, message: "姓名不能为空", trigger: "blur" }
                    ],
                    companyPhone:[
                        { required: true, message: "联系方式不能为空", trigger: "blur" },
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    phone:[
                        { required: true, message: "联系方式不能为空", trigger: "blur" },
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    address:[
                        { required: true, message: "地址不能为空", trigger: "blur" }
                    ],

                    address: [
                        { required: true, message: "账号不能为空", trigger: "blur" }
                    ],
                },
                gridData:[

                ],
                dialogTableVisible:false,
                companyInfo:false,
                form: {},
                dialogFormVisible:false,
                tip:false,
                haveLogin:false,
                total:0,
                queryParams: {
                    pageNum: 1,
                    pageSize: 10
                },
                iconName: '奥瑞动态',
                currentPage:1,
                news: [],
                token:'',
            }
        },
        watch: {
        },
        mounted() {
            this.getList()
            if(localStorage.getItem('token')) {
                this.haveLogin = true
                this.dialogFormVisible = false
                this.token = localStorage.getItem('token')
            }
            if(localStorage.getItem('loginUser')) {
                this.loginUser = JSON.parse(localStorage.getItem('loginUser'))
            }
            this.getDictS()
        },
        methods: {
            changeProvince() {
                this.cities = [];
                this.area = [];
                this.selectCity = "";
                this.selectArea = "";
                let cityItem = this.provinces.filter(
                    (item) => item.value === this.selectProvince
                );
                if (cityItem[0]) {
                    this.cities = cityItem[0].children;
                }
            },
            changeCity() {
                this.area = [];
                this.selectArea = "";
                let areaItem = this.cities.filter(
                    (item) => item.value === this.selectCity
                );
                if (areaItem[0]) {
                    this.area = areaItem[0].children;
                }
            },
            showComponent() {
                this.showComponent = true;
                this.currentComponent = 'UserAgreement';
                this.$refs.button.click();
            },

            //查询字典值
            getDictS(){

                indexApi.getCompanyDict('proficient_type').then(response=> {
                        if(response.data.code == 200) {
                            this.proficientDict=response.data.data;
                            console.log(this.proficientDict)
                        }else {
                            this.$message({type: 'info', message:response.data.msg});
                        }
                })

                indexApi.getCompanyDict('scale_dict').then(response=> {
                    if(response.data.code == 200) {
                        this.scaleDict=response.data.data;
                    }else {
                        this.$message({type: 'info', message:response.data.msg});
                    }
                })
            },
            // 取消按钮
            cancel() {
                //企业信息弹框关闭
                this.companyInfo = false;
            },
            //保存企业信息
            companySave() {
                if (this.agree) {
                    this.form.id=this.loginUser.id;
                    if (this.getCodeToText(this.selectProvince.split()) === 'undefined'){
                        this.form.province = this.selectProvince
                    }else {
                        this.form.province=this.getCodeToText(this.selectProvince.split());
                    }
                    if (this.getCodeToText(this.selectCity.split()) === 'undefined'){
                        this.form.city = this.selectCity
                    }else {
                        this.form.city=this.getCodeToText(this.selectCity.split());
                    }
                    if (this.getCodeToText(this.selectArea.split()) === 'undefined'){
                        this.form.county = this.selectArea
                    }else {
                        this.form.county=this.getCodeToText(this.selectArea.split());
                    }

                    indexApi.companySave(this.form).then(response=> {
                        if(response.data.code == 200) {
                            this.companyInfo = false;
                            this.form.loginUser.adeptCategory = this.form.adeptCategory
                            this.$message({type: 'info', message:response.data.msg});
                        }else {
                            this.$message({type: 'info', message:response.data.msg});
                        }
                    })
                } else {
                    alert('请先同意隐私条款与用户协议');
                }
            },
            getCodeToText(codeArray) {
                let area = "";
                switch (codeArray.length) {
                    case 1:
                        area += codeToText[codeArray[0]];
                        break;
                    case 2:
                        area += codeToText[codeArray[0]] + "/" + codeToText[codeArray[1]];
                        break;
                    case 3:
                        area +=
                            codeToText[codeArray[0]] +
                            "/" +
                            codeToText[codeArray[1]] +
                            "/" +
                            codeToText[codeArray[2]];
                        break;
                    default:
                        break;
                }
                return area;
            },
            // 普通商品兑换确认
            save() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        indexApi.addChangeFun(this.form).then(response=> {
                            if(response.data.code == 200) {
                                this.addModel = false
                                this.$alert('申请成功，预计客服将在7个工作日内联系您核销兑换的商品，请注意接听电话！', '申请成功', {
                                confirmButtonText: '确定',
                                confirmButtonClass: "confirmbtn"})
                            } else {
                                this.$message({type: 'info', message:response.data.msg});
                            }
                        })
                    }
                });
            },
            openExchange(item) {
                this.form = {
                    goodsName: item.name,
                    goodsPhoto:item.photo,
                    goodsType:item.type,
                    goodsId:item.id,
                    points:item.points,
                    userId: this.loginUser.id,
                    userNumber: this.loginUser.userNumber,
                    name:'',
                    phone:'',
                    address:'',// 也存续费账号
                }
                
                if(item.type =='1') { // 1普通商品
                    this.addModel = true
                    this.$refs['form'].resetFields();
                } else if(item.type =='2') { //2 超排账号兑换 
                    this.$alert('您要兑换的是：'+item.name, '确认兑换', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,  //是否显示取消按钮
                    confirmButtonClass: "confirmbtn",
                    callback: action => {
                        if(action == 'confirm') {
                            this.addNewCount() // 新增账号兑换
                        }
                        
                    }})
                } else if(item.type =='3') { // 3 超排账号续费
                    this.addRenewModel = true
                    this.$refs['form'].resetFields();
                }
                
            },
            // 续费商品兑换确认
            saveRenew() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                    indexApi.addChangeFun(this.form).then(response=> {
                        if(response.data.code == 200) {
                            this.addRenewModel = false
                            this.$alert('兑换成功！', '申请成功', {
                            confirmButtonText: '确定',
                            confirmButtonClass: "confirmbtn"})
                        } else {
                            this.$message({type: 'info', message:response.data.msg});
                        }
                    })
                    }
                });
            },
            // 新增超排账号兑换
            addNewCount() {
                indexApi.addChangeFun(this.form).then(response=> {
                    if(response.data.code == 200) {
                        this.$alert(response.data.msg, '申请成功', {
                        confirmButtonText: '确定',
                        confirmButtonClass: "confirmbtn"})
                    } else {
                        this.$message({type: 'info', message:response.data.msg});
                    }
                })
            },
            showExchange() {
                indexApi.getChangeListFun(this.loginUser.userNumber).then(response=> {
                    if(response.data.code == 200) {
                        this.exchangeList = response.data.data
                        this.exchange = true
                    } else if(response.data.code == 401) {
                        this.$alert('token失效,请重新登录！', '提示', {
                            confirmButtonText: '确定',
                            confirmButtonClass: "confirmbtn",
                            callback: action => {
                                this.loginout()
                                this.exchange = false
                            }
                        })
                    } else {
                        this.$message({type: 'info', message:response.data.msg});
                    }
                })
                
            },
            showDetail() {
                indexApi.getadFinishFun(this.loginUser.userNumber).then(response=> {
                    if(response.data.code == 200) {
                        this.pointsList = response.data.data
                        this.dialogTableVisible = true
                    } else if(response.data.code == 401) {
                        this.$alert('token失效,请重新登录！', '提示', {
                        confirmButtonText: '确定',
                        confirmButtonClass: "confirmbtn",
                        callback: action => {
                            this.loginout()
                            this.dialogTableVisible = false
                        }
                    })
                        
                    } else {
                        this.$message({type: 'info', message:response.data.msg});
                    }
                })
            },
            companyDetail() {
                this.companyInfo = true
                this.form = this.loginUser
                this.selectProvince = this.loginUser.province
                this.selectCity = this.loginUser.city
                this.selectArea = this.loginUser.county
            },
            showTip() {
                this.$alert('请通过完成奥瑞绘图仪输出中心与超排输出中心等任务获取积分', '如何获取积分', {
                confirmButtonText: '确定',
                confirmButtonClass: "confirmbtn",
                callback: action => {
                    // this.$message({
                    // type: 'info',
                    // message: `action: ${ action }`
                    // });
                }
                })
                this.tip = true
            },
            login() {
                if (this.agree) {
                    this.$refs["form"].validate(valid => {
                        if (valid) {
                            indexApi.login(this.form).then(response => {
                                console.log(response)
                                if (response.data.code == 200) {
                                    this.haveLogin = true
                                    this.dialogFormVisible = false
                                    localStorage.setItem('token', response.data.data.token)
                                    this.token = localStorage.getItem('token')

                                    localStorage.setItem('loginUser', JSON.stringify(response.data.data))
                                    this.loginUser = JSON.parse(localStorage.getItem('loginUser'))

                                    this.$message({type: 'success', message: response.data.msg});
                                } else {
                                    this.$message({type: 'info', message: response.data.msg});
                                }
                            })
                        }
                    });
                } else {
                    alert('请先同意用户协议');
                }
            },
            showlogin() {
                this.form = {
                    userName:'',
                    password:''
                }
                this.dialogFormVisible = true
                this.$refs['form'].resetFields();
            },
            loginout() {
                this.haveLogin = false
                localStorage.removeItem('loginUser')
                localStorage.removeItem('token')
            },
            /**查询兑换商品 */
            getList () {
                indexApi.getadChangeFun(this.queryParams.pageNum,
                this.queryParams.pageSize).then(response=> {
                    this.news = response.data.rows
                    this.total = response.data.total
                })
            },
            handleSizeChange(val) {
                this.queryParams.pageSize = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.queryParams.pageNum = val
                this.getList()
            },
        }
    }
</script>
<style>
::-webkit-scrollbar {
    width: 0px; /*对垂直流动条有效*/
    height: 0px; /*对水平流动条有效*/
    background-color:  transparent;
}
.el-button:focus, .el-button:hover {
    background: none !important;
    border-color: #ddd !important;
    color: #999 !important;
}
.confirmbtn {
    background:#E60012 !important;
    border-color: #E60012 !important;
    color: #fff !important;
}

.el-button--primary {
    background: #E60012 !important;
    border-color: #E60012 !important;
    color: #fff !important;
}

.el-button--primary:focus, .el-button--primary:hover {
    background: #E60012 !important;
    border-color: #E60012 !important;
    color: #fff !important;
}

.el-dialog__header {
    background: #000 !important;
    border-bottom: 1px solid #E60012;
    padding: 10px 20px 10px !important;
}
.el-dialog__title { 
    color: #fff !important;
}
.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
    color: #E60012 !important;
}
.el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__headerbtn:hover .el-message-box__close {
    color: #E60012 !important;
}
</style>
<style scoped>
.cursor {
    cursor: pointer;
}
.pagination>>>.el-pagination__total {
    font-size: 18px !important;
    color: #000 !important;
}
.pagination>>>.el-pager li {
    color: #000 !important;
    font-size: 18px !important;
}
.pagination>>>.el-pager li.active {
    color: #E60012 !important;
    font-size: 20px !important;
}
.pagination>>>.el-pagination__jump {
    font-size: 18px !important;
    color: #000 !important;
}
.btn-c {
    width: 100px;
    height: 40px;
    background: rgb(230, 0, 18);
    border-radius: 5px; 
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.btn-c:hover {
    background: rgb(221, 50, 67);
    color: #fff;
}
</style>