<template>
    <div :class="`rn-callto-action ${checkStyleType}`"
        :style="{'background-image': `url(${require(`@/assets/images/bg/foot.png`)})`,'background-size': `100% 100%`, 'height': '100%','padding': '40px 0'}"
         :data-black-overlay="setOverlay">
        <div class="container">
            <template v-if="styleType === 1">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <div class="content text-center">
                                <h2 class="title" v-html="title" data-aos="fade-up" data-aos-delay="60"/>
                                <h6 class="subtitle" v-html="subtitle" data-aos="fade-up" data-aos-delay="60"/>
                                <div class="call-to-btn" data-aos="fade-up" data-aos-delay="60">
                                    <a class="btn-default" href="#">{{ btnText }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 2">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="inner">
                            <div class="content text-center">
                                <h2 class="title mb--0" v-html="title" data-aos="fade-up" data-aos-delay="60"/>
                                <p v-html="subtitle" data-aos="fade-up" data-aos-delay="60"/>
                                <div class="call-to-btn text-center" data-aos="fade-up" data-aos-delay="60">
                                    <a class="btn-default btn-icon" href="#">
                                        {{ btnText }}
                                        <Icon name="arrow-right" class="size-20"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 3">
                <div class="row row--0 align-items-center content-wrapper" data-aos="fade-up" data-aos-delay="60">
                    <div class="col-lg-8">
                        <div class="inner">
                            <div class="content text-start">
                                <h2 class="title" v-html="title"/>
                                <h6 class="subtitle" v-html="subtitle"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="call-to-btn text-start text-lg-end">
                            <a class="btn-default" href="#">{{ btnText }}</a>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 4">
                <div class="row row--0 align-items-center content-wrapper" data-aos="fade-up" data-aos-delay="60">
                    <div class="col-lg-8">
                        <div class="inner">
                            <div class="content text-start">
                                <h2 class="title" v-html="title"/>
                                <h6 class="subtitle" v-html="subtitle"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="call-to-btn text-start text-lg-end">
                            <a class="btn-default" href="#">{{ btnText }}</a>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 5">
                <div class="row row--0 align-items-center content-wrapper theme-shape"
                     data-aos="fade-up"
                     data-aos-delay="60">
                    <div class="col-lg-12">
                        <div class="inner">
                            <div class="content text-center">
                                <h2 class="title" v-html="title"/>
                                <h6 class="subtitle" v-html="subtitle"/>
                                <div class="call-to-btn text-center">
                                    <a class="btn-default btn-icon" href="#">
                                        {{ btnText }}
                                        <Icon name="arrow-right" class="size-20"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 6">
                <div class="row row--0 align-items-center content-wrapper">
                    <div class="col-lg-8 col-12">
                        <div class="inner">
                            <div class="content text-start" data-aos="fade-up" data-aos-delay="60">
                                <h2 class="title" v-html="title"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-12">
                        <div class="call-to-btn text-start text-lg-end mt_md--20 mt_sm--20"
                             data-aos="fade-up"
                             data-aos-delay="60">
                            <a class="btn-default" href="#">{{ btnText }}</a>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 7">
                <div class="row row--0 align-items-center content-wrapper">
                    <div class="col-lg-8 col-md-8">
                        <div class="inner">
                            <div class="content text-start">
                                <Logo/>
                                <p class="subtitle" v-html="subtitle" data-aos="fade-up" data-aos-delay="60"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="call-to-btn text-start mt_sm--20 text-md-end"
                             data-aos="fade-up"
                             data-aos-delay="60">
                            <a class="btn-default btn-icon"
                               href="#">
                                {{ btnText }}
                                <Icon name="arrow-right" class="size-20"/>
                            </a>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="styleType === 8">
                <div class="row row--0">
                    <div class="col-lg-8">
                        <div>
                            <div class="row">
                                <div class="col-lg-2" v-if="tabs.length>0">
                                    <span class="tip-title">智能解决方案</span>
                                    <div class="link-hover" v-for="(item,index) in tabs" :key="index">
                                        <router-link :to="'/techHd?name='+item.name" style="font-size: 14px;">{{item.name}}</router-link>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <span class="tip-title">技术服务</span>
                                    <div class="link-hover">
                                        <router-link to="/serviceIndex" style="font-size: 14px;">服务政策</router-link>
                                    </div>
                                    <div class="link-hover">
                                        <router-link to="/softwareVideo?model=1" style="font-size: 14px;">软件视频</router-link>
                                    </div>
                                    <div class="link-hover">
                                        <router-link to="/softwareVideo?model=2" style="font-size: 14px;">硬件视频</router-link>
                                    </div>
                                    <div class="link-hover">
                                        <router-link to="/softwareVideo?model=3" style="font-size: 14px;">相关下载</router-link>
                                    </div>
                                    <div class="link-hover">
                                        <router-link to="/softwareVideo?model=4" style="font-size: 14px;">常见问题解答</router-link>
                                    </div>
                                    <!-- <div class="link-hover"><router-link to="/onlineRepair" style="font-size: 14px;">网上报修</router-link></div> -->
                                </div>
                                <div class="col-lg-2">
                                    <span class="tip-title">关于我们</span>
                                    <div class="link-hover"><router-link to="/about?id=1" style="font-size: 14px;">关于奥瑞</router-link></div>
                                    <div class="link-hover"><router-link to="/about?id=2" style="font-size: 14px;">企业荣誉</router-link></div>
                                    <div class="link-hover"><router-link to="/about?id=3" style="font-size: 14px;">发展历程</router-link></div>
                                    
                                </div>
                                <div class="col-lg-2">
                                    <span class="tip-title">联系我们</span>
                                    <div class="link-hover"><router-link to="/joinHands" style="font-size: 14px;">诚邀合作</router-link></div>
                                    <div class="link-hover" style="margin-bottom:20px"><router-link to="/payType" style="font-size: 14px;">汇款方式</router-link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 inner">
                        <div>
                            <div>
                                <img src="@/assets/images/logo/tel.png" alt="" style="width:25px">
                                <span style="margin-left:20px;color:#B5B4B4">0551-6565-5852</span>
                            </div>
                            <div style="margin-top:40px">
                                <img src="@/assets/images/logo/address.png" alt="" style="width:20px">
                                <span style="margin-left:20px;color:#B5B4B4">安徽省合肥市庐阳产业园区天河路328号</span>
                            </div>
                            <div >
                                <img src="@/assets/images/logo/code.png" alt="" style="margin-right:80px;margin-top:40px" class="code-img1">
                                <img src="@/assets/images/logo/code2.png" alt="" style="margin-top:40px" class="code-img">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Logo from '../logo/Logo'

    export default {
        name: 'CallToAction',
        components: {Logo, Icon},
        props: {
            backgroundImage: {
                type: String,
                default: ''
            },
            title: {
                type: String
            },
            subtitle: {
                type: String
            },
            btnText: {
                type: String
            },
            styleType: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                tabs: [],
                queryParams: {
                    pageNum: 1,
                    pageSize: 99,
                },
            }
        },
        computed: {
            checkStyleType() {
                if (this.styleType === 1) return 'clltoaction-style-default bg_image bg_image_fixed';
                else if (this.styleType === 2) return 'clltoaction-style-default style-2 bg_image bg_image_fixed';
                else if (this.styleType === 3) return 'clltoaction-style-default style-3 bg_image';
                else if (this.styleType === 4) return 'clltoaction-style-default style-4';
                else if (this.styleType === 5) return 'clltoaction-style-default style-5';
                else if (this.styleType === 6) return 'clltoaction-style-default style-6';
                else if (this.styleType === 7) return 'clltoaction-style-default style-7';
                else if (this.styleType === 8) return 'rn-call-to-action content-wrapper';
                else return '';
            },
            setOverlay() {
                if (this.styleType === 1) return 7;
                if (this.styleType === 2) return 8;
            }
        },
        created() {
            this.getTabsList()
        },
        methods: {
            async getTabsList() {
                const res = await this.$http.get('/siteWeb/solve/list?pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize+'&isShow='+'2')
                this.tabs = res.data.rows
            },
            toHz() {
                this.$router.push({path:"/joinHands"})
            },
            topay() {
                this.$router.push({path:"/payType"})
            }
        }
    }
</script>
<style scoped>
.tip-title {
    font-size: 20px;
    font-family: 'Microsoft YaHei';
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
}
.tip-title:hover {
    color: #E60012;
}
.tip-url {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B5B4B4;
}
.tip-url:hover {
    color: #E60012;
}
</style>