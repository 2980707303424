<template>
    <div class="rbt-separator-mid">
        <div class="container">
            <hr class="rbt-separator m-0" style="border-top: 1px solid #787872;">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Separator'
    }
</script>