<template>
    <Layout :header-transparency="true" :show-top-header="false">
        
        <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="智能CAD软件"
                            title="智能CAD软件"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15 bg goods-width70">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 pt--60 pb--60"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp" >
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds1.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName">服装大师服装CAD软件</div>
                                <div class="goodName-des w400">开样系统，放码系统，排料系统，工艺单</div>
                                <div class="goods-price mb--20">￥89999起</div>
                                <div class="d-flex justify-content-end">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail()">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row--15 bg goods-width70 mt--20">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 pt--60 pb--60"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div>
                                <div class="goodsName">服装大师服装CAD软件</div>
                                <div class="goodName-des">开样系统，放码系统，排料系统，工艺单 拷贝</div>
                                <div class="goods-price mb--20">￥5000起</div>
                                <div class="d-flex btn-box-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center aling-item-center mt--10" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row--15  goods-width70 mt--20 flex-warp">
                    <div class="bg w50 pt--60 pb--60 mb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds3.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName">服装大师服装CAD软件</div>
                                <div class="goodName-des">开样系统，放码系统，排料系统，工艺单 拷贝 2</div>
                                <div class="goods-price mb--20">￥11800起</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:2%"></div>
                    <div class="bg w50 pt--60 pb--60 mb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds3.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName">服装大师服装CAD软件</div>
                                <div class="goodName-des">开样系统，放码系统，排料系统，工艺单 拷贝 2</div>
                                <div class="goods-price mb--20">￥11800起</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row--15  goods-width70 flex-warp">
                    <div class="bg w30 pt--60 pb--60 mb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds4.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName text-center">服装大师服装CAD软件</div>
                                <div class="goodName-des text-center" style="height:100px">开样系统，放码系统，排料系统，工艺单</div>
                                <div class="goods-price mt--40 mb--40 text-center">￥8000起</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:2%"></div>
                    <div class="bg w30 pt--60 pb--60 mb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds4.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName text-center">服装大师服装CAD软件</div>
                                <div class="goodName-des text-center" style="height:100px">开样系统，放码系统，排料系统，工艺单</div>
                                <div class="goods-price mt--40 mb--40 text-center">￥8000起</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:2%"></div>
                   <div class="bg w30 pt--60 pb--60 mb--20"
                         data-aos="slide-up"
                         data-aos-duration="800">
                        <div class="d-flex justify-content-around aling-item-center flex-warp">
                            <div class="d-flex justify-content-center aling-item-center" style="width: 400px;">
                                <img src="@/assets/images/demo/fzds4.png" alt="">
                            </div>
                            <div>
                                <div class="goodsName text-center">服装大师服装CAD软件</div>
                                <div class="goodName-des text-center" style="height:100px">开样系统，放码系统，排料系统，工艺单</div>
                                <div class="goods-price mt--40 mb--40 text-center">￥8000起</div>
                                <div class="d-flex justify-content-center">
                                    <div class="goods-btn-black margin-right20 btn-font-balck" @click="toDetail">了解更多</div>
                                    <!-- <div class="goods-btn-fff btn-font-balck color-hs" @click="toBuy">立即选购</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Brand from "../components/elements/brand/Brand";
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    import BlogPost from "../components/blog/BlogPost";
    import BlogPostMixin from "../mixins/BlogPostMixin";
    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../components/elements/portfolio/Portfolio";
    import Progressbar from "../components/elements/progressbar/Progressbar";
    import Timeline from "../components/elements/timeline/Timeline";
    import ServiceFour from "../components/elements/service/ServiceFour";

    export default {
        name: 'Startup',
        components: {
            ServiceFour,
            Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Brand,Separator, Layout, VueSlickCarousel},
        mixins: [BlogPostMixin],
        data() {
            return {
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    }
                ],
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'index',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                    {
                        image: 'index',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    }
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue.js Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ],
                progressData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ]
            }
        },
        methods: {
            toDetail() {
                var item = {
                    id: '123'
                }
                this.$router.push({name: 'softwareDeatil', param: {id: item.id}})
            },
            toBuy() {
               this.$router.push({name: 'buyOrder'})
            }
            
        }
    }
</script>
<style scoped>
.bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.flex1 {
    display: flex;
    align-items: center;
}
</style>