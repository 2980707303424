import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'

import axios from 'axios'
import VueTypedJs from 'vue-typed-js'

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

// 挂载一个自定义属性$http
Vue.prototype.$http = axios
// 全局配置axios请求根路径(axios.默认配置.请求根路径)
// axios.defaults.baseURL = 'http://192.168.31.162:8022' // 天佑
// axios.defaults.baseURL = 'http://47.100.109.76:8022' // 测试
// axios.defaults.baseURL = 'http://124.71.193.101:8022' // 正式
// axios.defaults.baseURL = 'http://127.0.0.1:8022' // 内网正式


axios.defaults.baseURL = 'https://www.cad361.com/api/' // 域名访问
// 图片下载路径
Vue.prototype.imgUpload = 'https://file.yuanqizhineng.cn'

new Vue({
  router,
  created () {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')
