<template>
    <div class="costume-screen">
        <!-- 最外内容 -->
        <div class="box-border">
        <div class="top">
            <div class="top-center flex-between">
                <div class="w30 flex-between">
                    <span class="font">今日系统已运行时长: </span>
                    <span class="font cfff">{{form.duration}}</span>
                </div>
                <div class="w39"></div>
                <div class="w31 flex-start">
                    <div class="w40">
                        <div class="btn-bac flex-between pdlr--20" @click="show=false" v-if="show">
                            <span class="btn-font" >历史报表</span>
                            <img src="../../assets/images/screen/fdj.png" alt="" style="width:20px">
                        </div>
                        <div class="btn-bac flex-between pdlr--20" @click="show=true" v-if="!show">
                            <span class="btn-font" >返回</span>
                            <img src="../../assets/images/screen/back.png" alt="" style="width:20px">
                        </div>
                    </div>
                   
                    <div class="w60 flex-between pdlr--20">
                        <div class="flex-start">
                            <img src="../../assets/images/screen/time.png" alt="" style="width:20px;margin-left: 10px;">
                            <div class="time">{{nowTime}}</div>
                        </div>
                        <div class="text-right">
                            <div class="time2">{{day}}</div>
                            <div class="time2">{{week}}</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="box-big" v-if="show">
            <el-row :gutter="10" type="flex" class="box">
            <el-col :span="12">
                <div class="box-left">
                    <div class="box-l-1">
                        <div class="name-font flex-center">
                            裁剪文件名称
                        </div>
                        <div class="max-height">
                            <vue-seamlessScroll :data="form.fileList" :class-option="defaultOption">
                                <div v-for="(item,index) in form.fileList" :key="index">
                                    <div class="table">
                                        <div class="flex-start">
                                            <div :class="index==0?'dop':'dop bac'"></div>
                                            <div class="text">{{item}}</div>
                                        </div>
                                        
                                    </div>
                                    <div class="line"></div>
                                </div>
                            </vue-seamlessScroll>
                        </div>
                    </div>
                </div>
                <div class="box-r flex-center">
                    <img src="../../assets/images/screen/img.gif"  class="img">
                </div>
            </el-col>
            <el-col :span="12">
                <div class="list flex-start">
                    <div class="w30 name flex-center">
                        裁剪图数：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.fileSum}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">个</div>
                    </div>
                </div>
                <div class="list flex-start">
                    <div class="w30 name flex-center">
                       排料信息：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.markInfo}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">米</div>
                    </div>
                </div>
                <div class="list flex-start">
                    <div class="w30 name flex-center">
                       切割时间：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.cutTime}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">小时</div>
                    </div>
                </div>

                <div class="list flex-start">
                    <div class="w30 name flex-center">
                      床裁剪长度：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.pathLength}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">米</div>
                    </div>
                </div>

                <div class="list flex-start">
                    <div class="w30 name flex-center">
                      切割速度：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.cutSpeed}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">米/分钟</div>
                    </div>
                </div>

                <div class="list flex-start">
                    <div class="w30 name flex-center">
                      衣片数：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.pieceCount}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">片</div>
                    </div>
                </div>

                <div class="list flex-start">
                    <div class="w30 name flex-center">
                      切割压力：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.cutPresure}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">档</div>
                    </div>
                </div>

                <div class="list flex-start">
                    <div class="w30 name flex-center">
                     过床压力：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.slPresure}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">档</div>
                    </div>
                </div>

                <div class="list flex-start">
                    <div class="w30 name flex-center">
                     研磨次数：
                    </div>
                    <div class="w40 flex-center">
                        <div class="num-box flex-center">
                            <span class="text-num">{{form.mdCount}}</span>
                        </div>
                    </div>
                    <div class="w30 flex-center">
                        <div class="name flex-center">次</div>
                    </div>
                </div>
            </el-col> 
            </el-row>
        </div>

        <div class="box-big" v-if="!show">
            <div class="box">
                <div style="padding:20px">
                    <div class="flex-between">
                        <div class="flex-start">
                            <div class="bac-time">
                                <el-date-picker class="select1" :clearable="false" style="width:485px;background: none;border:  none"
                                    v-model="timeRange"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </div>

                            <div class="file-bac select" style="margin-left:20px">
                                <el-input v-model="queryParams.taskName" placeholder="文件名称"></el-input>
                            </div>
                        </div>
                        
                        <div class="flex-start">
                            <div class="serch-box flex-center">
                                <div class="font" @click="search">搜索</div>
                            </div>

                            <div class="serch-box flex-center">
                                <div class="font" @click="reset">重置</div>
                            </div>
                        </div>
                    </div>
                    <div class="tab2">
                        <el-table  ref='tableshow' :data="processList" :row-class-name="rowClassName" :header-row-class-name="headerRowClassName"
                            style="width: 100%;margin: auto;background: transparent;margin-top:10px" :height="500">
                            <el-table-column prop="createTime" label="开始时间" align="center"></el-table-column>
                            <el-table-column prop="fileList" label="文件名称" align="center"></el-table-column>
                            <el-table-column prop="markInfo" label="排料信息(米)" align="center"></el-table-column>
                            <el-table-column prop="currentBed" label="裁剪床数" align="center"></el-table-column>
                            <el-table-column prop="cutTime" label="切割时间(小时)" align="center"></el-table-column>
                            <el-table-column prop="pathLength" label="床裁剪长度(米)" align="center"></el-table-column>
                            <el-table-column prop="cutSpeed" label="切割速度(米/分钟)" align="center"></el-table-column>
                            <el-table-column prop="pieceCount" label="衣片(片)" align="center"></el-table-column>
                        </el-table>
                        <div class="flex-center">
                            <pagination class="page" style="background:none;width:500px" v-show="total>0" :total="total"
                                 :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="querygetccDateKanBanList"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    </div>
</template>

<script>
import {getccDateKanBan,getccDateKanBanList} from "@/api/screen/index";
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: "screenIndex",
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
        form: {
            cutPresure: 0,
            cutSpeed:0,
            cutTime:0,
            duration:0,
            fileSum:0,
            markInfo:0,
            mdCount:0,
            pathLength:0,
            pieceCount:0,
            slPresure:0,
            fileList:[],
        },
        total: 0,
        queryParams: {
            companyId: 'YQ000002',
            deviceId: 'AR1200719109',
            taskName: '',
            begin:'',
            end: '',
            pageNum: 1,
            pageSize: 50,
        },
        timeRange: [],
        processList: [],
        show: true,

        nowTime: null,
        timer: null,
        day: null,
        week: null,
        taskName: null,
        userInfo:{},
        companyId:'YQ000002',
        deviceId: 'AR1200719109'
    };
  },
  // 监听属性 类似于data概念
  computed: {
      defaultOption () {
          return {
              step: 0.2, // 数值越大速度滚动越快
              limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
              hoverStop: true, // 是否开启鼠标悬停stop
              direction: 1, // 0向下 1向上 2向左 3向右
              openWatch: true, // 开启数据实时监控刷新dom
              singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
              singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
              waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
          }
      }
  },
  created() {
    this.getDefulTime()
  },
  mounted() {
    this.nowTimes()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // this.companyId = this.userInfo.dept.companyId
    // this.queryParams.companyId = this.userInfo.dept.companyId
    this.queryccDateKanBan()
    this.querygetccDateKanBanList()
  },
  methods: {
    // 根据工厂Id查询吊挂线
    queryccDateKanBan() {
        getccDateKanBan(this.companyId,this.deviceId).then(res=> {
            console.log(res,'000000')
            if(res.code == 200) {
                this.form = res.data
                if(this.form.cutTime) {
                    this.form.cutTime = parseFloat(this.form.cutTime).toFixed(2)
                }
                if(this.form.pathLength) {
                    this.form.pathLength = parseFloat(this.form.pathLength).toFixed(2)
                }
            } else {
                this.$modal.msg(res.msg);
            }
        })
    },
    // 历史报表
    querygetccDateKanBanList() {
        getccDateKanBanList(this.queryParams.companyId,this.queryParams.deviceId,this.queryParams.taskName,
        this.queryParams.begin,this.queryParams.end,this.queryParams.pageNum,this.queryParams.pageSize).then(res=> {
            if(res.code ==200) {
                this.total = res.total
                this.processList = res.rows
            } else {
                this.$modal.msg(res.msg);
            }
        })
    },
    search() {
        this.queryParams.pageNum = 1;
        if(this.timeRange.length>0) {
            this.queryParams.begin = this.parseTime(this.timeRange[0]).substring(0,10)
            this.queryParams.end = this.parseTime(this.timeRange[1]).substring(0,10)
        }
        this.querygetccDateKanBanList()
    },
    reset() {
        this.queryParams.pageNum = 1;
        this.queryParams.taskName = ''
        this.getDefulTime()
        this.querygetccDateKanBanList()
    },

    getDefulTime() {
      //获取新的时间(2019.4.12）
      let date = new Date()
      //获取当前时间的年份转为字符串
      let year = date.getFullYear().toString()   //'2019'
      //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
      let month = date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1).toString():(date.getMonth()+1).toString()  //'04'
      //获取天，判断是否小于10，如果是在字符串前面拼接'0'
      let da = date.getDate() < 10 ? '0'+date.getDate().toString():date.getDate().toString()  //'12'
      //字符串拼接，开始时间，结束时间
      let end = year + '-' + month + '-' + da  //当天
      // let beg = year + '-' + month + '-01'    //当月第一天
      this.timeRange = [this.getDay(-6),end] //将值设置给插件绑定的数据
      this.queryParams.begin = this.getDay(-6)
      this.queryParams.end = this.getDay(1) // 明天
    },
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
        var m = month;
        if (month.toString().length == 1) {
            m = "0" + month;
        }
        return m;
    },
    //显示当前时间（年月日时分秒）
    timeFormate(timeStamp) {
        let year = new Date(timeStamp).getFullYear();
        let month = new Date(timeStamp).getMonth() + 1 < 10 ? "0" + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
        let date = new Date(timeStamp).getDate() < 10 ? "0" + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
        let hh = new Date(timeStamp).getHours() < 10 ? "0" + new Date(timeStamp).getHours() : new Date(timeStamp).getHours();
        let mm = new Date(timeStamp).getMinutes() < 10 ? "0" + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes();
        let ss = new Date(timeStamp).getSeconds() < 10 ? "0" + new Date(timeStamp).getSeconds() : new Date(timeStamp).getSeconds();                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
        let week = new Date(timeStamp).getDay();
        let weeks = ["日", "一", "二", "三", "四", "五", "六"];
        let getWeek = "星期" + weeks[week];
        this.nowTime = hh + ":" + mm + ':' + ss 
        this.week = getWeek
        this.day =  year + "-" + month + "-" + date
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear()
    },
    clear() {
      clearInterval(this.nowTimes)
      this.nowTimes = null;
    },
    rowClassName({row, rowIndex}) {
        if (rowIndex % 2 == 1) {
            return 'tab-row-s';
        } else {
            return 'tab-row-d';
        }
    },
    headerRowClassName({row, rowIndex}) {
        return 'tab-row-header';
    },
  },
  destory() {
  }
}
</script>

<style scoped>
.page>>>.el-pagination__total {
    color: #fff !important;
}
.page>>>.el-pagination__jump {
 color: #fff !important;
}
.page>>>input {
    background: none !important;
    color: #fff;
}
.page>>>.el-pagination.is-background.btn-next{
    background-color: #f4f4f5;
    color: #58aaeb;
}
.query>>>.el-input__inner {
    background: #003567 !important;
    border: none !important;
}
.page>>>button {
    background: #0D1F2F !important;
    color: #fff;
}
.page>>> .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: linear-gradient(0deg, #010E4C, #004FD9) !important;
    border: none !important;
    opacity: 0.8 !important;
}
.page>>> .el-pagination.is-background .el-pager li {
    background-color: rgba(5, 49, 97, 0.1) !important;
    border: 1px solid #23DBFC;
    border-radius: 5px;
    color: #fff;
}

.page>>> .el-pagination .el-select .el-input .el-input__inner {
    border-color:#23DBFC !important;
}
.page>>>.el-pagination__editor.el-input .el-input__inner {
    border-color:#23DBFC !important;
}
.select>>>.el-input__inner {
    background: none!important;
    border:  none !important;
    color: #96DFF7 !important;
    width: 195px !important;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
}
</style>
<style lang="scss" scoped>
html {
  height: 100%;
  box-sizing: border-box;
}
.bac-time {
    width: 500px;
    padding: 3px 0;
    height: 100%;
    background-image: url("../../assets/images/screen/bac-time.png");
    background-size: 100% 100%;
}
.file-bac {
    width: 260px;
    padding: 3px 0;
    height: 100%;
    background-image: url("../../assets/images/screen/file-bac.png");
    background-size: 100% 100%;
}
.serch-box {
    cursor: pointer;
    width: 120px;
    // padding: 3px 0;
    height: 55px;
    background-image: url("../../assets/images/screen/serch.png");
    background-size: 100% 100%;
    .font {
        color: #96DFF7;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        letter-spacing: 10px;
    }
}
// 整体背景
.costume-screen {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/screen/bac-center.png");
    background-size: 100% 100%;
    padding: 10px;
    margin: 0;
}
.box-border {
    width: 100%;
    height: 100%;
}
.w40 {
    width: 40%;
}
.w30 {
    width: 30%;
}
.w31 {
    width: 31%;
}
.w39 {
    width: 39%;
}
.w60 {
    width: 60%;
}
.pdlr--20 {
    padding: 0 20px;
}
// 头部
.top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 10%;
    background-image: url("../../assets/images/screen/top-bac.png");
    background-size: 100% 100%;
    padding: 0 10px;
    .top-center {
        width: 100%;
        height: 50%;
        background-image: url("../../assets/images/screen/top-center.png");
        background-size: 100% 100%;
        padding: 5px 20px;

    }
    .text-right {
       text-align: right;
    }
    .btn-bac {
        width: 160px;
        height: 35px;
        background-image: url("../../assets/images/screen/btn-bac.png");
        background-size: 100% 100%;
        cursor: pointer;
    }
    .btn-font {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #96DFF7;
        letter-spacing: 5px;
    }
    .font {
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9BE6FF
    }
    .cfff {
        color: #fff;
    }
    .time {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #BDEEFF;
        margin-left: 10px;
    }
    .time2 {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9BE6FF;
    }
}

.name-font {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #F2F2F2;
    background: linear-gradient(0deg, #03BCFF 0.537109375%, #AAFCFF 98.6328125%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
.box-big {
    width: 100%;
    height: 89%;
    margin-top: 1%;
    padding: 20px 40px 20px 40px;
    background-image: url("../../assets/images/screen/box.png");
    background-size: 100% 100%;
}
.tab2 {
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    height: 660px;
    background-image: url("../../assets/images/screen/tab2.png");
    background-size: 100% 100%;
}
.box {
  
   padding: 10px 0;

    .box-r {
        width: 100%;
        height: 59%;
        background-image: url("../../assets/images/screen/gif-box.png");
        background-size: 100% 100%;
    }
    .box-left {
        width: 100%;
        height: 40%;
        background-image: url("../../assets/images/screen/tab-box.png");
        background-size: 100% 100%;
        .box-l-1 {
            padding: 20px;
            .max-height {
                height: 200px;
                overflow-y: auto;
                margin-top: 10px;
            }
            .table {
                padding: 15px 100px;
                .text {
                    font-size: 19px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #F2F2F2;
                }
            }
            .line {
                width: 800px;
                height: 1px;
                background: #68D8FE;
            }
            .dop {
                width: 15px;
                min-width: 15px;
                min-height: 15px;
                height: 15px;
                background: #FF9600;
                border-radius: 50%;
                margin-right: 20px;
            }
            .bac {
                background: #00FF00;
            }
        }
    }
    .list {
        width: 100%;
        height: 74px;
        background-image: url("../../assets/images/screen/list.png");
        background-size: 100% 100%;
        margin-bottom: 10px;
        padding: 0 10px;
        .num-box {
            width: 260px;
            height: 55px;
            background-image: url("../../assets/images/screen/num-box.png");
            background-size: 100% 100%;
            .text-num {
                font-size: 25px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #F2F2F2;
            }
        }
        .name {
           font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #F2F2F2;
            background: linear-gradient(0deg, #03BCFF 0.537109375%, #AAFCFF 98.6328125%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
</style>
<style >
.el-table .tab-row-d {
    background: transparent;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
    background: rgba(0,162,255,0.2) !important;
    color: #9EE2FF;
}
.el-table::before, .el-table--group::after, .el-table--border::after {
    background-color: transparent;
}

.el-table .tab-row-d td {
    border: 0px;
    padding: 5px 0px !important;
    font-weight: 400;
    font-size: 18px;
    color: #9EE2FF;
}

.el-table .tab-row-s {
    background: rgba(110, 202, 255, 0.1);
}
.el-table .tab-row-s td {
    border: 0px;
    padding: 5px 0px !important;
    font-size: 18px;
    font-weight: 400;
    color: #9EE2FF;
}

.el-table .tab-row-header {
   background: rgba(0,162,255,0.2) !important;
}

.el-table .tab-row-header th {
    background: transparent;
    color: #fff;
    font-size: 22px !important;
    font-weight: 500 !important;
    border-bottom: 1px solid #52C4FF  !important;
    border-top: 1px solid #52C4FF !important;
    padding: 0px !important;
}
.el-date-editor .el-range-input {
    background: none!important;
    color: #96DFF7 !important;
}
.el-date-editor .el-range-separator {
    color: #96DFF7 !important;
}
</style>
<style>
/*滚动条的宽度*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: #022B61;
  background-clip: padding-box; /*背景被裁剪到内边距框   content-box 背景裁剪到内容框*/
  min-height: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #022B61;
}

/*滚动条凹槽的颜色，还可以设置边框属性*/
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}

::-webkit-scrollbar-track-piece {
  background-color: #052251;
}
</style>

