<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <div class="container goods-width60">
                <div class="row ">
                  <div class="d-flex mt--10 mb--20 cursor-pointer" >
                    <div class="name-des" style="color:#787878" @click="$router.go(-1)">{{form.oneType}}></div>
                    <div class="name-des" style="color:#787878" @click="goBack(form.oneType,form.twoType)">{{form.twoType}}></div>
                    <div class="name-des">{{form.name}}</div>
                  </div>
                  <div class="name-top">
                    {{form.name}}
                  </div>
                </div>
            </div>

            <div class="containermax mt--20 mb--20 flex-start2">
                <div class="goods-width60 d-flex">
                    <div  v-for="(item,index) in tabs" :key="index" @click="clickTabs(item.name)">
                        <div :class="item.check?'text-18 cursor-pointer':'text-18 cursor-pointer c-000'" style="margin-right:20px">
                        {{item.name}}
                        <div class="current" v-if="item.check"></div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="goods-width60">
                <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-12 col-12" v-show="form.goodsImages">
                        <div class="text-center">
                            <a class="ml--30" @click="changeImage"><img src="@/assets/images/icons/left.png" alt="" style="height:18px;cursor: pointer;"></a>
                            <ul style="min-height: 400px;">
                                <ol>
                                    <img :src="imgUpload+image" alt="" style="height:120px;margin-top:10px" v-on:mouseenter="changeImg(image)">
                                </ol>
                                <ol class="img-zzc" v-for="(item,index) in form.goodsImages" :key="index" >
                                    <img v-if="item!==image" :src="imgUpload+item" alt="" style="height:120px;margin-top:10px" v-on:mouseenter="changeImg(item)">
                                </ol>
                            </ul>
                            <a @click="changeImage" class="ml--30"><img src="@/assets/images/icons/right.png" style="height:18px;cursor: pointer;"></a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="display: flex;align-items: center;justify-content: center;">
                       <img :src="imgUpload+image" style="height:450px">
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 pl--20">
                        <div>{{form.keyWord1}} | {{form.keyWord2}}</div>
                        <div class="name-des">{{form.name}}</div>
                        <div class="flex-start2 text16" v-for="(item,index) in form.sellingPointList" :key="index">
                            <div class="dop-sm"></div>{{item}}
                        </div>
                        <div class="mt--20 mb--20">
                            <span class="text16">价格</span>
                            <span class="goods-price ml--40">￥{{form.price}}</span>
                        </div>

                        <div class="flex-start2 mt--20">
                            <div class="text16" style="width: 80px;">产品型号</div>
                            <div class="grid2">
                                <div class="bor text16 ml--20 mt--10" 
                                    :title="item" v-for="(item,index) in form.modelNumbers" :key="index">
                                    {{item}}
                                </div>
                            </div>
                        </div>

                        <div class="flex-start2 mt--20" v-if="form.goodsService">
                            <div class="text16" style="width: 80px;">产品服务</div>
                            <div class="grid2">
                                <div class="bor text16 ml--20 mt--10" 
                                    :title="item" v-for="(item,index) in form.goodsServices" :key="index">
                                    {{item}}
                                </div>
                            </div>
                        </div>

                        <!-- <div class="flex-start2 mt--20">
                            <span class="text16">购买数量</span>
                            <div class="ml--20">
                                <input type="number" v-model="number" style="width:100px;height:50px" :min='1' :max="999">
                            </div>
                        </div> -->
                        <div class="mt--40 flex-end mr--10">
                            <div class="goods-btn-fff btn-font-balck color-hs red-btn c-fff" @click="serviceShow = true">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="mt--60" v-if="iconName=='产品详情'">
                    <div class="row flex-center" v-for="(item,index) in form.videosList" :key="index">
                        <img :src="imgUpload+item" v-if="item.indexOf('.mp4')==-1">
                        <video playsinline="true"  v-else
                        x-webkit-airplay="allow" webkit-playsinline="true" preload="meta"
                        key="local" name="F9连供喷墨绘图机+18%.mp4"
                        style="width:100%;background:#000000;height:650px;"
                        controls="controls" controlslist="nodownload"
                        unlocked="1">
                        <source :src="imgUpload+item"
                        type="video/mp4">
                        <img width="400" height="400"
                        allowfullscreen="true" alt=" " style=""></video>
                    </div>
                </div>
            </div>
            <div class="containermax mt--20 mb--20 flex-start2" v-if="iconName=='销售详情'">
                <div class="goods-width70">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">买家</div>
                        <div class="col-lg-6 text-18 c-000">选项​​信息</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12text-18 c-000">数量</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">成交时间</div>
                    </div>
                </div>
            </div>

            <div class="goods-width70 " v-if="iconName=='销售详情'">
                    <div class="row bor-bt pb--10">
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">w***O</div>
                        <div class="col-lg-6 text-18 c-000">产品型号:AR-1900PPH / 上门安装:含上门安装</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12text-18 c-000">1</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">2021-10-11  11:15:42</div>
                    </div>
                    <div class="row bor-bt pb--10">
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">w***O</div>
                        <div class="col-lg-6 text-18 c-000">产品型号:AR-1900PPH / 上门安装:含上门安装</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12text-18 c-000">1</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">2021-10-11  11:15:42</div>
                    </div>
                    <div class="row bor-bt pb--10">
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">w***O</div>
                        <div class="col-lg-6 text-18 c-000">产品型号:AR-1900PPH / 上门安装:含上门安装</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12text-18 c-000">1</div>
                        <div class="col-lg-2 col-md-6 col-sm-12 col-12 text-18 c-000">2021-10-11  11:15:42</div>
                    </div>
                </div>
        </div>

        <el-dialog :visible.sync="serviceShow" :width="tkWidth" style="margin-top:10%" :show-close='false' class="customClass">
            <div class="tk-box" :style="{'background-image': `url(${require(`@/assets/images/kf.png`)})`}">
            <div class="flex-end pr--5 pt--5 cursor-pointer">
                <img src="@/assets/images/icons/close.png" alt="" class="red-close" @click="serviceShow = false">
            </div>
            <div class="flex-between">
                <div class="w50"></div>
                <div class="w50">
                    <div class="kf-text text-center" >
                        联系客服获取报价
                    </div>
                    <div class="text-center mt--20 mb--20">
                        <img :src="imgUpload+wxImage" alt="" class="code-size">
                    </div>
                    <div class="kf-text text-center" v-if="phone">
                        联系方式: {{phone}}
                    </div>
                </div>
            </div>
            </div>                
        </el-dialog>

    </Layout>
</template>

<script>
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    import BlogPost from "../components/blog/BlogPost";

    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../components/elements/portfolio/Portfolio";
    import Progressbar from "../components/elements/progressbar/Progressbar";
    import Timeline from "../components/elements/timeline/Timeline";

    export default {
        name: 'detail',
        components: { Timeline, Progressbar, Portfolio, BlogPost, SectionTitle,Separator, Layout, VueSlickCarousel},
     
        data() {
            return {
                tkWidth: '100',
                serviceShow: false,
                iconName: '产品详情',
                tabs:[{
                    name:'产品详情',
                    check: true,

                },
                // {
                //     name:'销售详情',
                //     check: false,
                // }
                ],
                number: 1,
                form: {},
                goodsId:'',

                image: '',
                maxIndex: 0,
                imgIndex: 0,
                wxImage: '',
                phone: '',

            }
        },
        created() {
            this.getImage()
            if(this.$route.query.id) {
                this.goodsId = this.$route.query.id
                this.getDeatilById()
            }
        },
        methods: {
            goBack(name,tabName) {
                this.$router.push({name: 'hardware', query: {name:name,tabName: tabName}})
            },
            changeImg(img) {
                // this.image = img
            },
            async getImage() {
                const res = await this.$http.get('/siteWeb/customer/list')
                this.wxImage = res.data.rows[0].wxImage
                this.phone = res.data.rows[0].phone
            },
            getDeatilById(){
                this.$http.get('/siteWeb/goodsInfo/'+this.goodsId).then(res=>{
                    this.form = res.data.data
                    if(this.form.keyWord) {
                        this.form.keyWord1 = this.form.keyWord.split(',')[0]
                        this.form.keyWord2 = this.form.keyWord.split(',')[1]
                    }
                    if(this.form.sellingPoint) {
                        this.form.sellingPointList = this.form.sellingPoint.split(',')
                    }
                    if(this.form.modelNumber){
                        this.form.modelNumbers = this.form.modelNumber.split(',')
                    }
                    if(this.form.goodsService){
                        this.form.goodsServices = this.form.goodsService.split(',')
                    }
                    if(this.form.goodsImage) {
                        this.form.goodsImages = this.form.goodsImage.split(',')
                        this.imgIndex = 0
                        this.maxIndex = this.form.goodsImages.length-1
                        this.image = this.form.goodsImages[0]
                    }
                    if(this.form.videos) {
                        this.form.videosList = this.form.videos.split(',')
                    }
                })
            },
            changeImage() {
                if(this.imgIndex<this.maxIndex) {
                    this.imgIndex =  this.imgIndex + 1
                    this.image = this.form.goodsImages[this.imgIndex]
                } else {
                    this.imgIndex = 0
                    this.image = this.form.goodsImages[0]
                }
                this.form.goodsImages.splice(this.imgIndex,1);
                this.form.goodsImages.push(this.image);
                this.$forceUpdate()
            },
            clickTabs(name) {
                this.iconName = name
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
            }
        }
    }
</script>
<style scoped>
.bor:hover {
    background: #E60012;
    color: #fff;
    border: #E60012 1px solid;
}
.customClass>>>.el-dialog__header {
    padding: 0px !important;
}
.customClass>>>.el-dialog__body {
    padding: 0px !important;
}
</style>