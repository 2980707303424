<template>
    <ul class="mainmenu">
        <li class="has-droupdown has-menu-child-item">
            <a href="/index" class="name">
                首页
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <!-- <ul class="submenu"> -->
                <!-- <li><router-link to="/index">登录后首页</router-link></li> -->
            <!-- </ul> -->
        </li>
        <li ><router-link to="/hardware?name=智能硬件" class="name">智能硬件</router-link></li>
        <li ><router-link to="/hardware?name=智能软件" class="name">智能软件</router-link></li>
        <!-- <li ><router-link to="/software" class="name">智能软件</router-link></li> -->
        <li class="has-droupdown has-menu-child-item">
            <a class="name">
                智能解决方案
            </a>
            <ul class="submenu" v-if="tabs.length>0">
                <li v-for="(item,index) in tabs" :key="index"><router-link :to="'/techHd?name='+item.name">{{item.name}}</router-link></li>
            </ul>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <a  href="/newIndex?name=奥瑞动态" class="name">
                新闻中心
            </a>

            <ul class="submenu">
                <li><router-link to="/newIndex?name=奥瑞动态">奥瑞动态</router-link></li>
                <li><router-link to="/newIndex?name=行业资讯">行业资讯</router-link></li>


                <!-- <li><router-link to="/newInfo">行业资讯</router-link></li> -->
            </ul>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <a href="/serviceIndex" class="name">
                技术服务
            </a>
            <ul class="submenu">
                <li><router-link to="/serviceIndex">服务政策</router-link></li>
                <li><router-link to="/softwareVideo?model=1">软件视频</router-link></li>
                <li><router-link to="/softwareVideo?model=2">硬件视频</router-link></li>
                <li><router-link to="/softwareVideo?model=3">相关下载</router-link></li>
                <li><router-link to="/softwareVideo?model=4">常见问题解答</router-link></li>

                <!-- <li><router-link to="/hardwareVideo">硬件视频1</router-link></li>
                <li><router-link to="/download">相关下载2</router-link></li>
                <li><router-link to="/answer">常见问题解答3</router-link></li> -->
            </ul>
        </li>
        <!-- <li><router-link class="name" to="/contact">技术支持</router-link></li> -->
        <li class="has-droupdown has-menu-child-item">
            <a href="/about?id=1" class="name">
                关于我们
            </a>
            <ul class="submenu">
                <li><router-link to="/about?id=1">关于奥瑞</router-link></li>
                <li><router-link to="/about?id=2">企业荣誉</router-link></li>
                <li><router-link to="/about?id=3">发展历程</router-link></li>
            </ul>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <a href="/contact" class="name">
                联系我们
            </a>
            <ul class="submenu">
                <li><router-link to="/contact">联系我们</router-link></li>
                <li><router-link to="/joinHands">诚邀合作</router-link></li>
                <li><router-link to="/payType">汇款方式</router-link></li>
            </ul>
        </li>
        <!-- <li><router-link class="name" to="/contact">联系我们</router-link></li> -->
        <!-- <li><router-link class="name" to="/test">English Version</router-link></li> -->
        <!-- <li><router-link class="name" to="/login">登录</router-link></li> -->

        <!-- <li class="has-droupdown has-menu-child-item">
            <a href="/exchange" class="name">
                兑换商品
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
        </li> -->
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon},
        data() {
            return {
                tabs: [],
                queryParams: {
                    pageNum: 1,
                    pageSize: 99,
                },
            }
        },
        created() {
            this.getTabsList()
        },
        methods: {
            async getTabsList() {
                const res = await this.$http.get('/siteWeb/solve/list?pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize+'&isShow='+'2')
                this.tabs = res.data.rows
            },
        }

    }
</script>
<style scoped>
.mainmenu>>>.name:hover {
    /* color: #E60012 !important; */
}
</style>