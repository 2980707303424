<template>
    <div class="parent">
        <h1 v-bind:style="{color: textColor}">奥瑞积分用户协议</h1>
        <p>合肥奥瑞数控科技有限公司（下文统称：奥瑞科技）在此特别提醒用户认真阅读本协议条款，您有权接受或不接受本协议。
            除非您接受本协议条款，否则您无权使用奥瑞积分。您使用奥瑞积分的行为将视为对本《协议》的接受，并同意接受本《协议》各项条款的约束。</p>
        <h6 v-bind:style="{color: textColor}">一、什么是奥瑞积分：</h6>
            奥瑞积分是奥瑞科技对购买使用本公司产品用户的一种回馈，积分不具有货币或现金价值，不可兑现，不可转让。用户可通过特定渠道观看视频，分享视频等方式获得积分，所获得的积分可在奥瑞积分商城兑换各类权益，如兑换商品或参与抽奖等。
        <h6 v-bind:style="{color: textColor}">二、如何获取积分：</h6>
            1、通过奥瑞科技提供的渠道观看视频获取相应的积分，具体以页面提示为准；
            2、分享奥瑞科技相应的视频获取相应的积分，具体以页面提示为准；
            其他获取方式请留意奥瑞科技官网活动。
        <h6 v-bind:style="{color: textColor}">三、积分兑换：</h6>
            <p>1、积分商城提供虚拟商品和实物商品两大类，用户可通过积分兑换方式获取相应的商品。请用户理解，由于受奥瑞科技政策、商品库存等因素的影响，商城商品兑完即止，先兑先得，具体商品信息请以兑换页面说明为准。
            <p>2、使用奥瑞积分在积分商城兑换，所消耗积分不提供操作修正、兑现或退还积分服务。
            <p>3、奥瑞积分作为用户回馈，用户通过积分兑换等方式获取的商品概不提供发票。
            <p>4、用户在积分商城内通过积分兑换的商品，如需邮寄，运费由用户自行承担。
            <p>5、用户兑换的虚拟商品将直接通过积分商城自动发放，实物商品会由奥瑞科技工作人员在7个工作日内联系用户核实邮寄。
        <h6 v-bind:style="{color: textColor}">四、商品售后问题：</h6>
            <p>1、虚拟商品如出现无法使用，请提供给奥瑞科技客服人员，为您核实后将重新发放。
            <p>2、实物商品请用户在签收前确认包装是否完好，若发现商品缺少、破损、残缺、错发等情况请拒绝签收。拒签的商品在快递退件后，奥瑞科技客服会与您联系核实相关情况。一旦成功签收则将视为本次积分兑换及配送服务已完成。
            <p>3、实物商品自用户签收起24小时内，如发现产品存在质量问题，可通过在线客服提供产品的照片，工作人员会在7个工作日内进行审核，核实商品为非人为损坏后，用户可邮寄回奥瑞科技进行同商品换货（如同商品缺货则返还积分），邮费由奥瑞科技承担。若商品为人为损坏，用户则需根据商品生产厂家的相关规定付费维修及承担相关货运费用。
            <p>4、实物商品在签收后，若用户在正常使用过程中出现质量问题，商品产生相关的维修费用需用户自行承担。
        <h6 v-bind:style="{color: textColor}">五、积分使用规则：</h6>
            <p>1、奥瑞积分是用户充值或参与活动时由系统赠送给用户的额外奖励，对积分的获取的途径、方式、数量、有效期，奥瑞科技拥有最终解释权。
            <p>2、奥瑞积分暂无有效期，作为回馈仅能兑换积分商城里的商品使用，不能转赠、兑现。
            <p>3、如因用户提供的邮寄信息及联系方式不真实，导致在商品寄送时无法送达或无人接收且多次联系未果造成退件的，奥瑞科技将视为用户主动放弃兑换商品，并不再进行商品补发，同时不予退还相应的积分。
            <p>4、任何组织和个人不得利用奥瑞积分，进行积分或礼包倒卖、转卖、交易等非法牟利行为，不得以任何不正常手段恶意获取积分，一经发现，奥瑞科技将严肃处理。包括但不限于：冻结积分、扣除积分、清零积分直至封停账号。
        <h6 v-bind:style="{color: textColor}">六、隐私保护</h6>
            <p>1、尊重并保护用户信息资源的隐私性是奥瑞科技的一贯制度，奥瑞科技将会采取合理的措施保护用户的信息资源，除法律或政府要求或用户同意等原因外，奥瑞科技不向除合作单位以外的第三方公开、透露用户信息资源。但用户与奥瑞科技及合作单位之间就用户信息资源公开或使用另有约定的除外，同时用户应自行承担因此可能产生的任何风险，奥瑞科技对此不予负责。
                一般而言，奥瑞积分基于下列原因需要使用到用户的信息资源：
            <p>（1）奥瑞科技向用户寄送实物商品；
            <p>（2）提高您的使用安全性和性能体验并提供客户支持。
            <p>（3）奥瑞积分可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与积分同等的保护用户隐私的责任，则奥瑞积分可将用户的注册资料等提供给该第三方。
        <h6 v-bind:style="{color: textColor}">七、免责条款</h6>
            <p>1、奥瑞积分作为对奥瑞用户的一种回馈，如因用户密码丢失所产生的一切法律责任与纠纷一概与奥瑞科技无关。
            <p>2、如出现账号丢失、积分被盗等情况，请尽快与奥瑞科技联系。当用户找回账号密码后如实物商品还未寄送，可予以冻结寄送，返还积分。如积分兑换的实物商品已寄送或兑换虚拟商品、参与抽奖等活动，奥瑞科技将无法返还用户积分，所造成的损失需用户自行承担。
            <p>3、为了进一步改善用户体验，奥瑞科技将可能不时更新积分服务的内容，积分规则也可能会随之更新，奥瑞科技将会以公告、积分商城通知、短信或弹窗等多种方式（统称“通知”）就更新的内容向您告知，更新内容将在前述通知指定日期开始生效。若您不同意更新后内容，您有权停止使用相关服务。您也可以随时在本页面查阅积分规则的最新版本。
            <p>4、本积分活动开展期间，如出现不可抗力等情况，例如发生自然灾害事件、遭受网络攻击或电信故障、停机维护、疫情、活动受法律法规、监管机构要求或政策指令需要停止举办或调整, 奥瑞科技或第三方合作单位单方暂停、中止或终止提供服务的, 可免于承担责任。
        <h6 v-bind:style="{color: textColor}">八、附则</h6>
            <p>1、本协议的订立、执行和解释及争议的解决均应适用中国法律。
            <p>2、本协议任何条款的部分或全部无效者，不影响其它条款的效力。
            <p>3、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均应向奥瑞科技所在地法院提起诉讼。
            <p>合肥奥瑞数控科技有限公司
            2023年12月14日</p>
    </div>

</template>

<script>
    export default {
        name: "UserAgreement",
        data(){
            return{
                textColor: 'black'
            }
        }
    }
</script>
<style>
    .parent {
        display: grid;
        justify-items: center;
        align-items: center;
    }
</style>
