<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-750 bg_image"
            :style="{'background-image': `url(${require(`@/assets/images/contact/top-bac.png`)})`,'background-size': '100% 100%'}">
            <div class="container">
                <!-- <div class="row">
                    <div class="col-lg-6">
                        <div class="inner pt--80">
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                               我们用心倾听
                            </h1>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                               每一位用户的声音
                            </h1>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
      
      <div class="cneter-box pt--20" style="background-color: #fff;">
            <div class="flex-between w80 pb--40">
                <!-- <div class="min-w500">
                    <div class="top-t">全国吊挂、推广营销总监</div>
                    <div class="top-b">张总经理  13739294330</div>
                </div> -->
                <div class="min-w500">
                    <div class="top-t">星链智能工厂项目部总监 </div>
                    <div class="top-b">周部长 15209852335</div>
                    <div class="top-b">负责：星链智能工厂项目全国推广</div>
                </div>
                <div class="min-w500">
                    <div class="top-t">吊挂推框项目部总监</div>
                    <div class="top-b">张总经理 13739294330 </div>
                    <div class="top-b">负责：智能吊挂流水线、智能仓储系统</div>
                </div>
            </div>

            <div class="flex-between w80 pb--40">
                <div class="min-w500">
                    <div class="top-t">数字化智慧版房项目部总监</div>
                    <div class="top-b">宋部长 15056980352</div>
                    <div class="top-b">负责：数字化智慧版房项目全国推广</div>
                </div>
                <div class="min-w500">
                    <div class="top-t">智慧充绒充棉中心项目部总监 </div>
                    <div class="top-b">叶经理 15375516246</div>
                    <div class="top-b">负责：智慧充绒充棉项目全国推广</div>
                </div>
            </div>

            <div class="flex-between w80 pb--40">
                <div class="min-w500">
                    <div class="top-t">CAM（多层裁床、铺布机）项目部总监</div>
                    <div class="top-b">桑经理 18657167898</div>
                    <div class="top-b">负责：各型多层裁床、铺布机</div>
                </div>
                  <div class="min-w500">
                    <div class="top-t">CAD项目部总监</div>
                    <div class="top-b">曹部长 13866119010</div>
                    <div class="top-b">负责：各型喷墨喷切设备等CAD系列产品</div>
                </div>
            </div>
        </div>
        <div class="cneter-box pt--20 pb--20">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="2023年奥瑞科技营销区域"
                        title="2023年奥瑞科技营销区域"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>

            <div class="flex-between w80" style="padding:0 30px">
                <div class="top-t" style="width:30%">负责区域</div>
                <div class="top-t" style="width:40%">联系方式 </div>
                <div class="top-t" style="width:30%">负责人</div>
            </div>
            <div class="flex-between w80" style="padding:0 30px" v-for="(item,index) in brandList" :key="index">
                <div class="top-b" style="width:30%">{{item.city}}</div>
                <div class="top-b" style="width:40%">{{item.tel}} </div>
                <div class="top-b" style="width:30%">{{item.name}}</div>
            </div>
        </div>


        

          <div class="con-box w80 pt--40 pb--40">
           <div class="left">
                <div>
                    <div class="title1">售后服务联系方式：</div>
<!--                    <div class="des">售后服务专线：0551-65663619（硬件）、0551-65370898（软件）</div>-->
                    <div class="des">硬件售后服务专线:0551-65663619 微信:15156993945</div>
                    <div class="des">软件技术服务专线:0551-65370898 QQ:834092441</div>
                    <!-- <div class="des">传真：0551-65655852</div> -->
                    <div class="des">投诉电话：18855176148（7x24小时）</div>
                    <!-- <div class="des">企业邮箱：sales@cad361.com</div> -->
                </div>
           </div>
            <div class="left">
                <div>
                    <div>
                        <span class="title1">销售咨询热线:</span>
                        <span class="des">0551-6565-5852</span>
                    </div>
                    <div>
                        <span class="title1">企业网站:</span>
                        <span class="des">www.CAD361.com</span>
                    </div>
                </div>
           </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                name: '关于奥瑞',
                tabs: [
                    {name: '关于奥瑞',check:true},
                    {name: '企业荣誉',check:false},
                    {name: '发展历程',check:false}
                ],
                brandList: [
                    {name: '胡慧椿',tel: '17611239975', city: '电商渠道'},
                    {name: '周海东',tel: '15209852335', city: '大客户'},

                    {name: '桑高林',tel: '18657167898', city: '浙江省、上海市'},
                    {name: '朱明风',tel: '13034053405', city: '广东省'},

                    {name: '张翠萍',tel: '18156503459', city: '广东省'},
                    {name: '田甜',tel: '18255163320', city: '江苏省'},

                    {name: '叶宁',tel: '15375516246', city: '江西省、福建省、河南省'},

                    {name: '王晴',tel: '15655177023', city: '湖北省，湖南省'},
                    {name: '曹雪琴',tel: '13866119010', city: '山东省、河北省'},
                   
                    {name: '宋殿友',tel: '15056980352', city: '安徽省'},
                    {name: '夏晓东',tel: '13470998737', city: '四川省、河南省'},
                    {name: '罗海',tel: '13956980969', city: '广东省、广西省、海南省'},
                    {name: '张艳',tel: '13739294330', city: '东北、西北、新疆省、广西省'},
                    {name: '罗晓波',tel: '18130085731', city: '国外市场'},
                ],
            }
        },
        methods: {
            tabsChoose(index) {
                this.tabs[index].check = true
                this.name = this.tabs[index].name
                this.tabs.forEach((e,cindex) =>{
                    if(index!==cindex) {
                        e.check = false
                    }
                })
            }
        }
    }
</script>
<style scoped>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #F44336 !important;
    color: #fff !important;
}
.nav-pills .nav-link {
    cursor: pointer;
    color: #000 !important;
    margin-right: 40px;
}
.text-tip {
    font-size: 32px;
    font-family: 'iFontszhounianti';
    font-weight: 400;
    color: #E10000;
}
.text-bt-tip {
    margin: 40px 0;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #000000;
}
</style>