<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <div class="goods-width60">
                <div class="row">
                  <div class="d-flex mt--10 mb--20">
                    <div class="name-des" style="color:#787878">技术支持></div>
                    <div class="name-des" style="color:#787878">常见问题解答></div>
                    <div class="name-des">笔式绘图机日常保养小知识</div>
                  </div>
                </div>
            </div>
            <div class="goods-width60">
                <div class="row pt--40 pb--40 name-top">
                    笔式绘图机日常保养小知识
                </div>
                <div class="row">
                    <div class="font-size18 mt--20 mb--20">
                        1. 绘图机使用一段时间后，会有许多灰尘吸附在机器的各个部位.例如在笔车运行的轨道上有许多油垢，可用布头蘸少许机油擦拭轨道，去除油垢后再用干布擦去油迹，这样可保持笔车运行流畅
                    </div>

                    <div class="font-size18 mt--20 mb--20">
                    2. 为保持绘图纸在绘图时不被笔划破，要经常更换压条或更换压条上面的美纹纸，以保持压条表面平整。
                    </div>

                    <div class="font-size18 mt--20 mb--20">
                        3. 绘图机外接的电源插座一定要保证有可靠的接地线并且是左零右火。
                    </div>

                    <div class="font-size18 mt--20 mb--20">
                        4. 压轮的轮皮表面经常用酒精棉球清洁。
                    </div>

                    <div class="font-size18 mt--20 mb--20">
                        5. 送纸轴上的刺滚表面经常要用牙刷刷洗。
                    </div>

                    <div class="font-size18 mt--20 mb--20">
                        6. 卷纸杆、送纸杆的杆头螺丝、前后电机连接块的顶丝要经常检查是否松动。
                    </div>
                </div>


                

                <div class="row pb--40 pt--40">
                    <div class="line"></div>
                </div>

                <div class="row">
                   <div class="col-lg-6 pl--20">
                    <div class="font-size18 mt--20 mb--20">上一篇                    “清凉”献给最可爱的人 (之二)</div>
                    <div class="font-size18 mt--20 mb--20">上一篇                    “清凉”献给最可爱的人 (之二)</div>
                   </div>
                   <div class="col-lg-6 pr--20">
                        <div class="font-size18 mt--20 mb--20 flex-end">文章分类： 奥瑞动态</div>
                        <div class="font-size18 mt--20 mb--20 flex-end">分享到：
                            <div class="ml--10">
                                <img src="@/assets/images/icons/wx.png" alt="">
                            </div>
                            <div class="ml--10">
                                <img src="@/assets/images/icons/wb.png" alt="">
                            </div>
                            <div class="ml--10">
                                <img src="@/assets/images/icons/qq.png" alt="">
                            </div>
                            <div class="ml--10">
                                <img src="@/assets/images/icons/db.png" alt="">
                            </div>
                            <div class="ml--10">
                                <img src="@/assets/images/icons/tb.png" alt="">
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Brand from "../components/elements/brand/Brand";
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    import BlogPost from "../components/blog/BlogPost";
    import BlogPostMixin from "../mixins/BlogPostMixin";
    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../components/elements/portfolio/Portfolio";
    import Progressbar from "../components/elements/progressbar/Progressbar";
    import Timeline from "../components/elements/timeline/Timeline";
    import ServiceFour from "../components/elements/service/ServiceFour";

    export default {
        name: 'Startup',
        components: {
            ServiceFour,
            Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Brand,Separator, Layout, VueSlickCarousel},
        mixins: [BlogPostMixin],
        data() {
            return {
                // tkWidth:'40%',
                serviceShow: false,
                iconName: '产品详情',
                tabs:[{
                    name:'产品详情',
                    check: true,

                }, {
                    name:'销售详情',
                    check: false,
                }
                ],
                number: 1,
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    }
                ],
                sliderSetting: {
                    // centerMode:true,
                    // // centerPadding:'30px',
                    // infinite: true,
                    // slidesToShow: 1,
                    // slidesToScroll: 1,
                    // dots: true,
                    // vertical: true,
                    // verticalSwiping: true,
                    // swipeToSlide: true
                    arrows: true,
                    dots: true,
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    vertical: true,
                    verticalSwiping: true,
                    swipeToSlide: true,
                },
                sliderData: [
                    {
                        image: 'hty1.png',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                    {
                        image: 'hty2.png',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                     {
                        image: 'hty2.png',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                     {
                        image: 'hty2.png',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                     {
                        image: 'hty2.png',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    },
                     {
                        image: 'hty2.png',
                        title: `Auri-tech45喷墨绘图机`,
                        description: `HP45类型墨盒，ARM处理器，全程闭环定位，控制精度高， 运行速度快。`
                    }
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue.js Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ],
                progressData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
            }
        },
        methods: {
            clickTabs(name) {
                this.iconName = name
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
            },
            toDetail() {
                var item = {
                    id: '123'
                }
                this.$router.push({name: 'softwareDeatil', param: {id: item.id}})
            },
            toBuy() {
                alert("建设中");
            }
            
        }
    }
</script>
<style scoped>
.customClass>>>.el-dialog__header {
    padding: 0px !important;
}
.customClass>>>.el-dialog__body {
    padding: 0px !important;
}
.bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.flex1 {
    display: flex;
    align-items: center;
}
</style>