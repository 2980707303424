<template>
    <div :class="{'header-transparent-with-topbar': data.headerTransparency}">
        <!-- Start Header Area -->
        <header :class="[`rn-header header-default header-not-transparent header-sticky ${data.headerClass}`]" 
        :style="{'background-image': `url(${require(`@/assets/images/demo/top-bac.png`)})`,'background-size': `100% 100%`}">
            <div class="position-relative width80-nav">
                <div class="row align-items-center row--0" style="height: 118px;">
                    <template v-if="data.navAlignment === 'right' || data.navAlignment === 'left'">
                        <div class="col-lg-3 col-md-6 col-4"  style="justify-content: center;">
                            <Logo/>
                            <!-- <div class="topTextDes">100人工厂 200人效益 </div> -->
                            <div class="topTextDes">
                                <div>
                                    全国统一咨询热线：0551-6565-5852
                                </div>
                                <div>
                                    售后服务热线：0551-65663619
                                </div>
                                <div>
                                    服务投诉电话：18855176148
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-lg-8 col-md-6 col-8 position-static">
                            <div style="position: absolute; width: 240px;top: 15px;right: 20px;z-index: 999;" class="small-none">
                                <el-input placeholder="搜索" v-model="searchName" @input="toGoodsPage" :clearable="true"></el-input>
                            </div>
                            <div class="header-right">
                                <nav class="mainmenu-nav d-none d-lg-block">
                                    <Nav/>
                                </nav>
                          

                                <!-- Start Header Btn -->
                                <!-- <div class="header-btn">
                                    <a :class="data.buyButtonClass"
                                       href="#">
                                        立即购买123
                                    </a>
                                </div> -->
                                <!-- End Header Btn  -->

                                <!-- Start Mobile-Menu-Bar -->
                                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div class="hamberger">
                                        <button class="hamberger-button"
                                                @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                                            <Icon name="menu" size="21"/>
                                        </button>
                                    </div>
                                </div>
                                <!-- Start Mobile-Menu-Bar -->

                                <!-- <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                                    <ul>
                                        <li>
                                            <a href="javascript: void(0);"
                                               @click.prevent="AppFunctions.toggleClass('body', 'active-light-mode')">
                                                <img class="light-icon"
                                                     src="../../../assets/images/icons/sun-01.svg"
                                                     alt="Sun images">
                                                <img class="dark-icon"
                                                     src="../../../assets/images/icons/vector.svg"
                                                     alt="Moon Images">
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </template>
                    <!-- <template v-if="data.navAlignment === 'left'">
                        <div class="col-lg-9 col-md-6 col-4 position-static">
                            <div class="header-left d-flex">
                                <Logo/>
                                <nav class="mainmenu-nav d-none d-lg-block">
                                    <Nav/>
                                </nav>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-8">
                            <div class="header-right">
                                <!-- Start Header Btn -->
                                <!-- <div class="header-btn">
                                    <a :class="data.buyButtonClass"
                                       href="#">
                                       立即购买111
                                    </a>
                                </div>
                                <!-- End Header Btn  -->

                                <!-- Start Mobile-Menu-Bar -->
                                <!-- <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div class="hamberger">
                                        <button class="hamberger-button"
                                                @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                                            <Icon name="menu" size="21"/>
                                        </button>
                                    </div>
                                </div> -->
                                <!-- Start Mobile-Menu-Bar -->

                                <!-- <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                                    <ul>
                                        <li>
                                            <a href="javascript: void(0);"
                                               @click.prevent="AppFunctions.toggleClass('body', 'active-light-mode')">
                                                <img class="light-icon"
                                                     src="../../../assets/images/icons/sun-01.svg"
                                                     alt="Sun images">
                                                <img class="dark-icon"
                                                     src="../../../assets/images/icons/vector.svg"
                                                     alt="Moon Images">
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                            <!-- </div>
                        </div>
                    </template> -->
                </div>
            </div>
        </header>
        <!-- End Header Area -->

        <!-- Start Mobile Menu -->
        <MobileMenu/>
        <!-- End Mobile Menu -->

        <!-- Start Theme Style -->
        <div>
            <div class="rn-gradient-circle"/>
            <div class="rn-gradient-circle theme-pink"/>
        </div>
        <!-- End Theme Style -->
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import MobileMenu from './MobileMenu'
    import AppFunctions from '../../../helpers/AppFunctions'
    import Nav from './Nav'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Header',
        props: {
            data: {
                default: null
            }
        },
        components: {Logo, Nav, MobileMenu, Icon},
        data() {
            return {
                searchName: '',
                AppFunctions
            }
        },
        methods: {
            toGoodsPage() {
                this.$router.push({name: 'hardware',query: {searchName: this.searchName}})
            },
            toggleStickyHeader() {
                const scrolled = document.documentElement.scrollTop;
                if (scrolled > 100) {
                    AppFunctions.addClass('.header-default', 'sticky');
                } else if (scrolled <= 100) {
                    AppFunctions.removeClass('.header-default', 'sticky');
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.toggleStickyHeader);
        },
        mounted() {
            this.toggleStickyHeader();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleStickyHeader);
        }
    }
</script>
<style>
.el-input__inner:hover {
    border-color: #C0C4CC !important;
}
.el-input__inner:focus {
    border-color: #C0C4CC !important;
}
input[type=text]:focus{
    border-color: #C0C4CC !important;
}
</style>
<style scoped>
.topTextDes {
    color: #000;
    font-size: 14px;
    position: absolute;
    top: 25%;
    left: 19%;
}
@media screen and (max-width:768px){
    .topTextDes {
        top: 5%;
        left: 40%;
    }
}
</style>