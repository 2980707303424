<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="cneter-box pt--30" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="收款方式"
                        title="收款方式"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
            <div class="flex-cneter w80 name-top">
               通          知
            </div>
            <div class="flex-start w80 name-des">
               各经销商，合作伙伴及终端客户：  
            </div>
            <div class="flex-start w80 name-des mt--40">
                为方便大家采购产品及我司查询到账情况，现将付款方式统一公示如下，后期通过汇款采购产品及服务器，均以以下账户信息为准，凡支付至非公司账户的情况下，我司对汇款行为不做查询，并不负一切责任。
            </div>
        </div>


        <div class="cneter-box pt--100" style="background-color: #fff;">
             <div class="flex-cneter w80 name-top">
              奥瑞科技汇款方式
            </div>
            <div class="flex-between w80 pb--40">
                <div class="min-w500">
                    <div class="top-t">整机费用汇款：</div>
                    <div class="top-b">户名：合肥奥瑞数控科技有限公司</div>
                    <div class="top-b">账户：4990 1010 0100 6593 68 </div>
                    <div class="top-b">开户行： 兴业银行合肥分行营业部 </div>
                </div>
                <div class="min-w500">
                    
                    <div class="top-b" style="margin-top:36px">户名：合肥奥瑞数控科技有限公司</div>
                    <div class="top-b">账户：7326 8101 8260 0069 220</div>
                    <div class="top-b">开户行： 中信银行合肥财富广场支行 </div>
                </div>
            </div>

            <div class="flex-between w80 pb--40">
                <div class="min-w500">
                    <div class="top-t">配件支付宝汇款：</div>
                    <div class="top-b">支付宝名：合肥奥瑞数控科技有限公司</div>
                    <div class="top-b">支付宝账户：sales@oricad.com</div>
                </div>
                <div class="min-w500"></div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                name: '关于奥瑞',
                tabs: [
                    {name: '关于奥瑞',check:true},
                    {name: '企业荣誉',check:false},
                    {name: '发展历程',check:false}
                ],
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },

                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ],
                counterData: [
                    {
                        number: 199,
                        title: 'Happy Clients',
                    },
                    {
                        number: 575,
                        title: 'Employees',
                    },
                    {
                        number: 69,
                        title: 'Useful Programs',
                    },
                    {
                        number: 500,
                        title: 'Useful Programs',
                    }
                ],
                teamData: [
                    {
                        image: 'team-dark-01',
                        name: 'Sr Janen Sara',
                        designation: 'Sr Product Designer',
                        location: 'CO Miego, AD, USA',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-02',
                        name: 'Corporate Jane',
                        designation: 'Manager',
                        location: 'Bangladesh',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-03',
                        name: 'Jara Saraif',
                        designation: 'Software Developer',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                      {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    }
                ]
            }
        },
        methods: {
            tabsChoose(index) {
                this.tabs[index].check = true
                this.name = this.tabs[index].name
                this.tabs.forEach((e,cindex) =>{
                    if(index!==cindex) {
                        e.check = false
                    }
                })
            }
        }
    }
</script>
<style scoped>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #F44336 !important;
    color: #fff !important;
}
.nav-pills .nav-link {
    cursor: pointer;
    color: #000 !important;
    margin-right: 40px;
}
.text-tip {
    font-size: 32px;
    font-family: 'iFontszhounianti';
    font-weight: 400;
    color: #E10000;
}
.text-bt-tip {
    margin: 40px 0;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #000000;
}
</style>