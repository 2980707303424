<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <div class="goods-width60">
                <div class="row">
                  <div class="d-flex mt--10 mb--20">
                    <div class="name-des" style="color:#787878">技术支持></div>
                    <div class="name-des" style="color:#787878">{{className}}></div>
                    <div class="name-des">{{form.title}}</div>
                  </div>
                </div>
            </div>
            <div class="goods-width60">
                <!-- <div class="row pt--40 pb--40 name-top">
                    {{form.title}}
                </div> -->
                <div class="row">
                    <div class="mt--20 mb--20 kg-sty" v-html="form.content.replaceAll('/dev-api',imgUpload+'/dev-api')"></div>
                </div>

                <div class="row pb--40 pt--40">
                    <div class="line"></div>
                </div>

                <div class="row">
                   <div class="col-lg-6 pl--20">
                    <div class="font-size18 mt--20 mb--20 pointer"  v-if="lastTitle!=='无'"
                    @click="last(lastId)" :disabled="isLast" :class="{ disClick: isLast === true }">上一篇：
                        <span style="margin-left:40px">{{ lastTitle }}</span></div>

                    <div class="font-size18 mt--20 mb--20 pointer" v-if="nextTitle!=='无'"
                    :class="[nextBtn, { disClick: isNext === true }]" @click="next(nextId)" :disabled="isNext">下一篇：
                        <span style="margin-left:40px">{{ nextTitle }}</span></div>
                   </div>
                   <div class="col-lg-6 pr--20">
                        <div class="font-size18 mt--20 mb--20 flex-end">文章分类： {{className}}</div>
                        <div class="font-size18 mt--20 mb--20 flex-end">分享到：
                            <div class="ml--10" @click="shareToWeChat">
                                <img src="@/assets/images/icons/wx.png" alt="">
                            </div>
                            <div class="ml--10" @click="shareToMicroblog">
                                <img src="@/assets/images/icons/wb.png" alt="">
                            </div>
                            <div class="ml--10" @click="shareToQQRom">
                                <img src="@/assets/images/icons/qq.png" alt="">
                            </div>
                            <!-- <div class="ml--10" @click="shareToDouBan">
                                <img src="@/assets/images/icons/db.png" alt="">
                            </div> -->
                            <!-- <div class="ml--10" @click="shareToTb">
                                <img src="@/assets/images/icons/tb.png" alt="">
                            </div> -->
                        </div>
                   </div>
                </div>
            </div>

            
            <el-dialog :visible.sync="show" width="400px" style="margin-top:10%" title="分享到微信">
                <div>打开微信“扫一扫”，打开网页后点击屏幕右上角分享按钮</div>
                <div class="d-flex mt--20" style="justify-content: center;align-items: center;display: flex">
                    <div class="item-pic"  id="qrinvitecode" ref="qrcode"></div>
                </div>
            </el-dialog>
        </div>

        

    </Layout>
</template>

<script>
    import QRCode from 'qrcodejs2' 
    import Layout from "../components/common/Layout";
    export default {
        name: 'Startup',
        components: {Layout,QRCode},
        data() {
            return {
                show:false,
                shareUrl: location.href,

                form: {},
                id: '',
                className: '',
                iconName: '',
                nextTitle: '',
                nextId: '',
                isNext:false,
                isLast:false,
                lastTitle: '',
                nextBtn: '',
                
            }
        },
        mounted() {
            if(this.$route.query.id) {
                this.id = this.$route.query.id
                this.iconName = this.$route.query.iconName
                this.className = this.$route.query.className
                this.getList()
            }
        },
        methods: {
            closeCode () {
                this.$refs.qrcode.innerHTML = ''
            },
            getQRInviteCode() {  
            // 生成二维码
                this.show = true
                setTimeout(() => {
                    this.closeCode()
                    new QRCode('qrinvitecode', {  
                    width: 200,  //宽度
                    height: 200, // 高度  
                    text: location.href,
                    render: 'canvas' ,// 设置渲染方式（有两种方式 table和canvas，默认是canvas）  
                })
                }, 1000);
                
            },
            //分享到微博
            shareToMicroblog() {
                window.open(
                    "https://service.weibo.com/share/share.php?url=" +encodeURIComponent(this.shareUrl) +"&title="+this.form.title
                );
            },
            //分享到qq空间
            shareToQQRom() {
                window.open(
                    "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
                    encodeURIComponent(this.shareUrl)+"&title="+this.form.title+"&summary="+this.form.title
                );
            },
            //分享到微信 需要单独画页面
            shareToWeChat() {
                this.getQRInviteCode(); // 调用二维码的方法
            },
            //分享到豆瓣
            shareToDouBan() {
                window.open("http://shuo.douban.com/!service/share?name="+this.form.title+'&href='+encodeURIComponent(this.shareUrl))
            },
            //分享到贴吧
            shareToTb() {
                window.open("http://tieba.baidu.com/f/commit/share/openShareApi?url="+encodeURIComponent(this.shareUrl))
            },
            async getList () {
                const res = await this.$http.get('/siteWeb/technical/list?species='+this.className+'&isShow='+2+
                '&type='+this.iconName+'&pageNum='+1+'&pageSize='+9999)
                this.brandList = res.data.rows
                if(this.brandList.length>0) {
                    this.brandList.forEach((e,index) => {
                        if(e.id == this.id) {
                            this.form = e
                            if (index >= this.brandList.length - 1) {
                                this.nextTitle = '无';
                                this.isNext = true;
                            } else {
                                this.nextTitle = this.brandList[index + 1].title;
                                this.nextId = this.brandList[index + 1].id;
                            }
                            if (index == 0) {
                                this.lastTitle = '无';
                                this.isLast = true;
                            } else {
                                this.lastTitle = this.brandList[index - 1].title;
                                this.lastId = this.brandList[index - 1].id;
                            }
                        }
                    });
                }
            },
            //点击上一篇文章时;
            last(id){
                this.id = id
                this.getList()
            },
            //点击下一篇文章时
            next(id){
                this.id = id
                this.getList()
            },
        }
    }
</script>
<style>
iframe {
    width: 100%;
    min-height: 600px !important;
}
</style>
<style scoped>
.pointer {
    cursor: pointer;
}
.customClass>>>.el-dialog__header {
    padding: 0px !important;
}
.customClass>>>.el-dialog__body {
    padding: 0px !important;
}
.bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.flex1 {
    display: flex;
    align-items: center;
}
</style>