<template>
    <ul :class="[
            `brand-list`,
            {'brand-style-1': brandStyle === 1},
            {'brand-style-2': brandStyle === 2},
            {'brand-style-2': brandStyle === 3},
            {'brand-style-2 variation-2': brandStyle === 4},
        ]">
        <li v-for="(brand, index) in brandList" :key="index">
            <a style="margin:10px;background: #FFFFFF;box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);border-radius: 5px;height:120px">
                <img :src="brand.image" alt="Brand Image" style="height:100%"/>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'Brand',
        props: {
            brandList: {},
            brandStyle: {
                type: Number,
                default: 4
            }
        }
    }
</script>