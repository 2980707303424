<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="cneter-box pt--20" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        :subtitle="iconName"
                        :title="iconName"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
        </div>
        <div class="cneter-box pb--60" style="background-color: #fff;">
            <div class="w80 plr-10" v-for="(item,index) in news" :key="index">
               <div class="row mt--20 hover-box pt--20 pb--20" @click="toDetail(item)">
                    <div class="col-lg-2 date-box ">
                        <div class="flex-end100">
                            <div class="red-line"></div>
                        </div>
                        <div class="date pdlr-10">{{item.date}}</div>
                        <div class="date-day pdlr-10">{{item.day}}</div>
                    </div>
                    <div class="col-lg-4 text-center flex-center">
                        <img :src="imgUpload+ item.image" alt="" style="height: 180px;width:300px">
                    </div>
                    <div class="col-lg-6">
                        <div class="new-title">{{item.title}}</div>
                        <div class="new-des pdlr-10">
                            {{item.remark}}
                        </div>
                    </div>
               </div>
               <div class="line1"></div>
            </div>


            <div class="flex-center mt--40">
                 <el-pagination class="pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="queryParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                    </el-pagination>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    export default {
        name: 'About',
        components: {SectionTitle,Layout},
        data() {
            return {
                total:0,
                queryParams: {
                    pageNum: 1,
                    pageSize: 10
                },
                iconName: '奥瑞动态',
                currentPage:1,
                news: []
            }
        },
        watch: {
            $route: {
                handler: function(val, oldVal){
                    this.changeRoute(val)
                },
                // 深度观察监听
                deep: true
            }
        },
        mounted() {
            if(this.$route.query.name) {
                this.iconName = this.$route.query.name
                this.getList()
            }
        },
        methods: {
            async getList () {
                const res = await this.$http.get('/siteWeb/news/list?species='+this.iconName+'&isShow='+'2'
                +'&pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize)
                this.news = res.data.rows
                if(this.news&& this.news.length>0) {
                    this.news.forEach(ee=> {
                        ee.date = ee.releaseDate.split('-')[0]+'/'+ee.releaseDate.split('-')[1]
                        ee.day = ee.releaseDate.split('-')[2]
                    })
                }
                this.total = res.data.total
            },
            changeRoute(val) {
                this.iconName = val.query.name
                this.getList()
            },
            toDetail(item) {
                this.$router.push({name:'newsDetail',query: {id: item.id,iconName:this.iconName}})
            },
            handleSizeChange(val) {
                this.queryParams.pageSize = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.queryParams.pageNum = val
                this.getList()
            },
        }
    }
</script>
<style scoped>
.pagination>>>.el-pagination__total {
    font-size: 18px !important;
    color: #000 !important;
}
.pagination>>>.el-pager li {
    color: #000 !important;
    font-size: 18px !important;
}
.pagination>>>.el-pager li.active {
    color: #E60012 !important;
    font-size: 20px !important;
}
.pagination>>>.el-pagination__jump {
    font-size: 18px !important;
    color: #000 !important;
}
</style>