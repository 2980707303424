<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <!-- Start Slider Area -->
        <div v-if="name=='关于奥瑞'"  class="slider-area slider-style-1 height-750 bg_image"
            :style="{'background-image': `url(${require(`@/assets/images/bg/about-bg2.png`)})`,'background-size': '100% 100%'}">
            <div class="container">
                <!-- <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <div>
                                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                                    <span class="theme-gradient">Our Company's About Details.</span>
                                </h3>
                            </div>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                We are a Corporate <br/> Business Agency.
                            </h1>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div v-else class="slider-area slider-style-1 height-750 bg_image"
            :style="{'background-image': `url(${require(`@/assets/images/bg/about-bg.png`)})`,'background-size': '100% 100%'}">
            <div class="container">
                <!-- <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <div>
                                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                                    <span class="theme-gradient">Our Company's About Details.</span>
                                </h3>
                            </div>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                We are a Corporate <br/> Business Agency.
                            </h1>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div style="width: 100%;height: 100px;box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.2);justify-content: center;align-items: center;display: flex;" class="text-center">
           <ul class="nav nav-pills">
            <li class="nav-item" v-for='(item,index) in tabs' :key='index' >
                <a :class="item.check?'nav-link active':'nav-link'" @click="tabsChoose(index)">{{item.name}}</a>
            </li>
            </ul>

        </div>

        <div style="margin: 20px auto;color: #000000;" v-if="name=='关于奥瑞'" class="w80">
            <div class="mt--20 kg-sty" v-html="aboutContent.replaceAll('/dev-api',imgUpload+'/dev-api')"></div>

            <!-- <div class="mb--20 text-center name-top">我们是谁?</div>
            <div class="name-des">合肥奥瑞数控科技有限公司位于中国科教之城——安徽合肥，是一家集智能CAD/CAM系统研发、生产、销售、服务于一体的国家级高新技术企业，产品应用领域涉及服装、箱包、玩具、制鞋、车饰、家居、广告、包装等八个行业，拥有“服装大师”、“箱包大师”、“羽绒大师”、“斯米特”、“图王”、“布王”、“Auri-tech”、“酷拍”等业内著名自主品牌、数十项国家产品专利，是目前国内CAD/CAM系统领域一家具备软件自主研发与硬件规模化生产双重能力的国家双软企业。</div>

            <div class="container mt--40 mb--40" >
                <div class="row">
                    <div class="col-lg-4 col-md-6 mt--30"
                        v-for="(teamMember, index) in teamData"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="4"/>
                    </div>
                </div>
            </div>
 
            <div class="pt--20 name-des">
                在公司总部，2万多平方米的现代化生产厂房和研发办公中心，是公司发展壮大的根基；在国内外，遍布各地的60多家4S体验店和加盟推广中心，形成强大的营销服务网络体系。公司与国内外众多科研机构携手共建起互尊、互补、互信、互利的长期协作关系，系列产品不仅覆盖国内主要市场，同时远销美国、阿根廷、埃及、波兰、罗马尼亚、土耳其、韩国、孟加拉、马来西亚、新加坡、老挝、泰国、越南等多个国家和地区，市场占有率始终名列前茅。
            </div>
            <div  class="pt--20 name-des">
                合肥奥瑞科技走过十多年的风雨历程，伴随着纺织服装等传统劳动密集型产业一路走来，共同见证着整个行业的起步、蜕变与发展。时至今日，面临原材料成本持续上升、人力资源严重短缺、生产管理松散无序等诸多问题，传统企业亟需在经营理念上破茧转型，推行生产过程一体化、园区化、数字化的“智能化工厂”的需求日益明显。智能化工厂到底是什么样？简单的说，就像给工厂装上了一个能自主思考的“大脑”，在数字化工厂的技术上，利用物联网技术、设备监控技术等加强信息管理和服务，掌控产销流程、提高生产过程中的可控性，减少生产线上人工干预，即时正确地采集生产线数据，合理的管理生产进度。 
            </div>
            <div  class="pt--20 name-des">
                合肥奥瑞数控科技有限公司将针对服装、纺织、箱包、玩具、家具等传统型生产企业，侧重于生产环节推出软、硬件相结合的生产经营管理解决方案。以服装企业为例，在技术科采用CAD成套系统实现快速制版放码；在裁剪房应用自动铺布机、自动裁床进行精确快速裁剪；在用人最为密集、管理难度最高的缝制车间，采用智能吊挂流水线或推箱流水槽、模板缝纫设备等技术和产品，精减生产线用人数量，推行并保持标准化生产模式，利用智能设备采集的实时生产数据，加强现场监管，实现效能提升；在后道环节，推行智能整烫设备及自动仓储系统，确保产品数据能够及时、准确地反应出来，方便为销售部门快速提供成品信息。通过各环节技术与产品的协调运转，50人的工厂完全可以创造出100人的生产效益！
            </div>

            <div class="pt--20 pb--100 name-des">
                科技创造价值！新的经济形势下，摆脱单纯依靠人力的粗放经营模式，通过引进先进技术、设备来提高企业的生产效率和产品品质，是各行业尤其是传统行业的转型方向。奥瑞科技公司将致力于为企业持续导入能效管理理念，创新研发先进技术与设备，推广行业知识和项目管理经验，帮助广大企业显著提高生产效率，实现增效节能。
            </div>

            <div style=" display: flex;justify-content: center;align-items: center;">
                <div>
                    <div class="name-top" style="color: #E10000;">服务理念：品质一流 服务一流</div>
                    <div  class="pt--20 name-top" style="color: #E10000;">经营宗旨：用户至上 信誉至上</div>
                </div>
            </div>

            <div class="text-bt-tip">
                <div>网址：www.cad361.com</div>
                <div>邮箱：sales@cad361.com</div>
                <div>全国统一热线：0551-6565-5852</div>
                <div>传真：086-0551-65655852</div>
                <div>地址：中国合肥市庐阳产业园天河路328号</div>
            </div> -->
        </div>


        <div style="margin: 20px auto;color: #000000;" v-if="name=='企业荣誉'" class="w80">
            <div class="mb--20 text-center name-top">荣誉资质</div>
            
            <div class="container mt--30">
                <div class="grid5">
                    <div
                        v-for="(teamMember, index) in teamData1"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="5"/>
                    </div>
                </div>
            </div>

            <div class="container mt--80" >
                <div class="row">
                    <div class="col-lg-3 col-md-6 mt--20"
                        v-for="(teamMember, index) in teamData2"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="6"/>
                    </div>
                </div>
            </div>

            <div class="container mt--80">
                <div class="grid5">
                    <div
                        v-for="(teamMember, index) in teamData3"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="4"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="name=='发展历程'" class='w80'>
            <div class="row mt--40 mb--40">
                <div class="col-lg-4 flex-end">
                    <div class="">
                        <div class="font-size25 font-size22">为中小企业提供</div>
                        <div class="font-size25 mt--30">智能化解决方案</div>
                    </div>
                    
                </div>
                <div class="col-lg-1 flex-end"></div>
                <div class="col-lg-4">
                    <div class="font-size13">
                        合肥奥瑞数控科技有限公司位于中国科教之城——安徽合肥，是一家集智能CAD/CAM系统研发、生产、销售、服务于一体的国家级高新技术企业，产品应用领域涉及服装、箱包、玩具、制鞋、车饰、家居、广告、包装等八个行业，拥有“服装大师”、“箱包大师”、“羽绒大师”、“斯米特”、“图王”、“布王”、“Auri-tech”、“酷拍”等业内著名自主品牌、数十项国家产品专利，是目前国内CAD/CAM系统领域一家具备软件自主研发与硬件规模化生产双重能力的国家双软企业。
                    </div>
                </div>
            </div>
        </div>

        <div v-if="name=='发展历程'">
            <img src="@/assets/images/logo/red-title.png" alt="" style="height:300px;width:100%">

            <div class="top-h-line"></div>
            <div class="top-dop"></div>

            <div class="row row--15 w80" v-for="(item,index) in brandList" :key='index'>
                <div class="col-lg-5">
                   <div v-if="index%2==1">
                        <div class="flex-end1">
                            <div class="date1 flex-center">
                                {{item.time}}
                            </div>
                        </div>
                        <div class="flex-end1 mt--40 ml--30">
                            <img :src="item.image" alt="">
                        </div>
                   </div>
                </div>
                <div class="col-lg-2">
                    <div class="h-line2"></div>
                    <div class="top-dop"></div>
                    <div class="bottom-h-line" v-if="index == brandList.length-1"></div>
                </div>
                <div class="col-lg-5">
                    <div v-if="index%2==0">
                        <div class="date flex-center">
                            {{item.time}}
                        </div>
                        <div class="mt--40 ml--30">
                            <img :src="item.image" alt="">
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                name: '关于奥瑞',
                tabs: [
                    {name: '关于奥瑞',check:true},
                    {name: '企业荣誉',check:false},
                    {name: '发展历程',check:false}
                ],
                brandList: [
                    {
                        time: '2022',
                        image: require(`@/assets/images/demo/des1.png`)
                    },
                    {
                        time: '2021',
                        image: require(`@/assets/images/demo/des2.png`)
                    },
                    {
                        time: '2020',
                        image: require(`@/assets/images/demo/des3.png`)
                    },
                    {
                        time: '2017',
                        image: require(`@/assets/images/demo/des4.png`)
                    },
                    {
                        time: '2015',
                        image: require(`@/assets/images/demo/des5.png`)
                    },
                    {
                        time: '2013',
                        image: require(`@/assets/images/demo/des6.png`)
                    },
                    {
                        time: '2005',
                        image: require(`@/assets/images/demo/des7.png`)
                    }

                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ],
                counterData: [
                    {
                        number: 199,
                        title: 'Happy Clients',
                    },
                    {
                        number: 575,
                        title: 'Employees',
                    },
                    {
                        number: 69,
                        title: 'Useful Programs',
                    },
                    {
                        number: 500,
                        title: 'Useful Programs',
                    }
                ],
                teamData: [
                    {
                        image: 'team-dark-01',
                        // name: '服装、箱包、玩具、制鞋、车饰、家居、广告、包装',
                        // designation: 'Sr Product Designer',
                        // location: 'CO Miego, AD, USA',
                        // description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-02',
                        // name: '服装大师、箱包大师、羽绒大师、斯米特、图王、布王、Auri-tech、酷拍',
                    },
                    {
                        image: 'team-dark-03',
                        // name: 'Jara Saraif',
                      
                    },
                    {
                        image: 'team-dark-04',
                        // name: 'Afanan Sifa',
                       
                    },
                      {
                        image: 'team-dark-05',
                        // name: 'Afanan Sifa',
                       
                    },
                    {
                        image: 'team-dark-06',
                        // name: 'Afanan Sifa'
                    }
                ],
                teamData1:[
                    {image: '2'},
                    {image: '3'},
                    {image: '4'},
                    {image: '5'},
                    {image: '1'},
                ],
                teamData2:[
                    {image: 'team-01',name: '安徽省软件行业协会单位会员'},
                    {image: 'team-02',name:'质量管理体系认证证书'},
                    {image: 'team-03',name:'高新技术企业证书'},
                    {image: 'team-04',name:'软件企业认定证书'},

                    {image: 'team-05',name:'软件产品登记证书'},
                    {image: 'team-06',name:'软件产品登记证书'},
                    {image: 'team-07',name:'软件产品登记证书'},
                    {image: 'team-08',name:'软件产品登记证书'},
                ],
                teamData3:[
                    {image: 'team-09'},
                    {image: 'team-10'},
                    {image: 'team-11'},
                    {image: 'team-12'},
                    {image: 'team-13'},
                    {image: 'team-14'},
                    {image: 'team-15'},
                    {image: 'team-16'},
                    {image: 'team-17'},
                    {image: 'team-18'},
                ],
                aboutContent: '',
            }
        },
        watch: {
            $route: {
                handler: function(val, oldVal){
                    this.changeRoute(val)
                },
                // 深度观察监听
                deep: true
            }
        },
        created() {
            this.getList()
        },
        methods: {
            async getList () {
                const res = await this.$http.get('/siteWeb/CompanyInfo/1')
                this.aboutContent = res.data.data.content
            },
            changeRoute(val) {
                if(val.query.id == 1) {
                this.name = '关于奥瑞'
                this.getList()
                } if(val.query.id == 2) {
                    this.name = '企业荣誉'
                } if(val.query.id == 3) {
                    this.name = '发展历程'
                }
                this.tabs.forEach(ee=> {
                    if(ee.name == this.name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
            },
            tabsChoose(index) {
                this.tabs[index].check = true
                this.name = this.tabs[index].name
                this.tabs.forEach((e,cindex) =>{
                    if(index!==cindex) {
                        e.check = false
                    }
                })
                if(this.name == '关于奥瑞') {
                    this.getList()
                }
            }
        }
    }
</script>
<style scoped>
.flex-end1 {
    display: flex;
    justify-content: flex-end;
}
.date1 {
    width: 220px;
    height: 70px;
    background-image: url("../assets/images/logo/date-bac2.png");
    background-size: 100% 100%;
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.date {
    width: 220px;
    height: 70px;
    background-image: url("../assets/images/logo/date-bac.png");
    background-size: 100% 100%;
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.top-h-line {
    margin: auto;
    width: 10px;
    height: 150px;
    background: linear-gradient(0deg, #A8A8A8 0%, rgba(0,0,0,0) 100%);
}
.bottom-h-line {
    margin: auto;
    width: 10px;
    height: 150px;
    background: linear-gradient(180deg, #A8A8A8 0%, rgba(0,0,0,0) 100%);
}
.h-line2 {
    margin: auto;
    width: 10px;
    height: 555px;
    background: #A8A8A8;
}
.top-dop {
    margin: 10px auto;
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    background: #E60000;
    border-radius: 50%;
}
.font-size13 {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}

.font-size25 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E60012;
}
.font-size22 {
    font-size: 22px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #F44336 !important;
    color: #fff !important;
}
.nav-pills .nav-link {
    cursor: pointer;
    color: #000 !important;
    margin-right: 40px;
}
.text-bt-tip {
    margin: 40px 0;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #000000;
}
.grid5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
} 
</style>