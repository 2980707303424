<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <!-- <div class="containermax mb--20 flex-center" style="background: #FFFFFF;">
                <div class="goods-width50 flex-center">
                    <div class="cursor-pointer">
                        <img src="@/assets/images/icons/up-left.png" alt="" style="width:10px;margin-right:40px">
                    </div>
                    <div  v-for="(item,index) in tabs" :key="index" @click="clickTabs(item.name)">
                        <div :class="item.check?'text-18 cursor-pointer':'text-18 cursor-pointer c-000'" style="margin-right:20px">
                        {{item.name}}
                        <div class="current" v-if="item.check"></div>
                    </div>
                    </div>
                    <div class="cursor-pointer">
                        <img src="@/assets/images/icons/up-right.png" alt="" style="width:10px;margin-left:20px">
                    </div>
                </div>
            </div> -->
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="相关下载"
                            title="相关下载"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15  goods-width70 mt--15" v-for="(item,index) in brandList" :key='index'>
                    <div class="flex-between hover-box-small pl--10 pr--10 pt--20 pb--20" @click="todetail">
                         <div class="flex-start1">
                            <div class="dop1"></div>
                            <div class="font-size18">{{item.name}}</div>
                        </div>
                        <div class="font-size18">
                            2017-12-21
                        </div>
                    </div>
                    <div class="line1"></div>
                </div>


                <div class="flex-center mt--40">
                 <el-pagination class="pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="40">
                    </el-pagination>
            </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Brand from "../components/elements/brand/Brand";
    import Layout from "../components/common/Layout";
    import Separator from "../components/elements/separator/Separator";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    import BlogPost from "../components/blog/BlogPost";
    import BlogPostMixin from "../mixins/BlogPostMixin";
    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../components/elements/portfolio/Portfolio";
    import Progressbar from "../components/elements/progressbar/Progressbar";
    import Timeline from "../components/elements/timeline/Timeline";
    import ServiceFour from "../components/elements/service/ServiceFour";

    export default {
        name: 'Startup',
        components: {
            ServiceFour,
            Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Brand,Separator, Layout, VueSlickCarousel},
        mixins: [BlogPostMixin],
        data() {
            return {
                currentPage:1,
                iconName: '绘图机系列',
                tabs:[{
                    name:'绘图机系列',
                    check: true,

                }, {
                    name:'切割机系列',
                    check: false,
                },{
                    name:'充绒机系列',
                    check: false,
                },{
                    name:'铺布裁剪系列',
                    check: false,
                }
                ],
                brandList: [
                    {
                        name: '智能CAD最新版相关软件下载'
                    },
                    {
                        name: '上位机升级主板'
                    },
                    {
                        name: '上位机接线往一边一直错位'
                    },
                    {
                        name: '万能笔绘图模式'
                    },
                    {
                        name: '网络版安装'
                    },
                    {
                        name: '创建款式及规格表'
                    },
                    {
                        name: '万能笔绘图模式'
                    },
                    {
                        name: '万能笔捕捉模式'
                    },
                    {
                        name: '万能笔绘图模式'
                    },
                    {
                        name: '万能笔捕捉模式'
                    },
                ],
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            todetail() {
                this.$router.push({name: 'downloadDetail'})
            },
            clickTabs(name) {
                this.iconName = name
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
            }
            
        }
    }
</script>
<style scoped>
.pagination>>>.el-pagination__total {
    font-size: 18px !important;
    color: #000 !important;
}
.pagination>>>.el-pager li {
    color: #000 !important;
    font-size: 18px !important;
}
.pagination>>>.el-pager li.active {
    color: #E60012 !important;
    font-size: 20px !important;
}
.pagination>>>.el-pagination__jump {
    font-size: 18px !important;
    color: #000 !important;
}
</style>