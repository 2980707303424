<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="slider-area slider-style-1 height-750 bg_image"
            :style="{'background-image': `url(${require(`@/assets/images/bg/znhd.png`)})`,'background-size': '100% 100%'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <h1 class="title" data-aos="fade-up" data-aos-delay="150">
                                智能解决方案 <br/> 
                                <h4>lntelligent solutions</h4>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 70px;box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.2);justify-content: center;align-items: center;display: flex;" class="text-center">
            <div v-for="(item,index) in tabs" :key="index" @click="clickTabs(item.name)">
                    <div :class="item.check?'text-18 cursor-pointer':'text-18 cursor-pointer c-000'" style="margin-right:20px">
                    {{item.name}}
                    <div class="flex-center">
                        <div class="current" v-if="item.check"></div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="w80 mt--40 mb--40">
            <SectionTitle v-if="iconName"  text-align="center" :subtitle="iconName"  :title="iconName"  description="100人工厂200人效益" data-aos="fade-up"/>
      

            <div class="row">
                <span class="mt--20 mb--20" v-html="form.content.replaceAll('/dev-api',imgUpload+'/dev-api')"></span>
                <!-- <div class="col-lg-6 flex-end">
                    <img src="@/assets/images/demo/znhd.png" alt="">
                </div>
                 <div class="col-lg-6 flex-start">
                    <img src="@/assets/images/demo/znhd2.png" alt="">
                </div> -->
            </div>
        </div>


        <div style="width: 100%;height: 268px;background:#F7F4F8" class="flex-center">
            <div>
                <div class="flex-center mt--20 titlename1">遇到问题需要帮助吗？</div>
                <div class="flex-center mt--20 titlename2" >遇到一个无法解决的问题需要寻求我们的帮助？我们一直在这里等您。</div>
                <div class="flex-center mt--20">
                    <el-popover
                        placement="right"
                        width="200"
                        trigger="click"
                        >
                        <img :src="imgUpload+image">
                        <img slot="reference" src="@/assets/images/logo/btn.png" alt="" style="height:40px;cursor: pointer;">
                    </el-popover>
                </div>
                  
            </div>
        </div>
        <div style="width: 100%;height: 268px;background:#fff"></div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    export default {
        name: 'About',
        components: {SectionTitle, Separator, Layout},
        data() {
            return {
                form: {
                    content:'',
                },
                total:0,
                queryParams: {
                    pageNum: 1,
                    pageSize: 99,
                },
                iconName: '',
                name: '',
                tabs: [
                    // {name: '智能设计排料',check:false},
                    // {name: '智能验布预缩',check:false},
                    // {name: '智能铺布裁剪',check:false},

                    // {name: '智能工厂模块',check:false},
                    // {name: '智能缝制',check:false},
                    // {name: '智能后道',check:true},

                    // {name: '智能仓储',check:false},
                    // {name: '星链工厂计划',check:false}
                ],
                image: '',
            }
        },
        watch: {
            $route: {
                handler: function(val, oldVal){
                    this.changeRoute(val)
                },
                // 深度观察监听
                deep: true
            }
        },
        mounted() {
            this.getTabsList()
            this.getImage()
        },
        methods: {
            async getImage() {
                const res = await this.$http.get('/siteWeb/customer/list')
                if(res.data.rows.length>0) {
                    this.image = res.data.rows[0].wxImage
                }  
            },
            async getTabsList() {
                const res = await this.$http.get('/siteWeb/solve/list?pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize+'&isShow='+'2')
                this.tabs = res.data.rows
                if(this.tabs && this.tabs.length>0) {
                    this.iconName = this.tabs[0].name
                    this.tabs.forEach(ee=> {
                        ee.check = false
                        if(ee.name == this.iconName) {
                            ee.check = true
                            this.form = ee
                        }
                    })
                }
            },
            async getList () {
                const res = await this.$http.get('/siteWeb/solve/list?name='+this.iconName+'&pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize+'&isShow='+'2')
                this.form = res.data.rows[0]
                this.total = res.data.total
            },
            changeRoute(val) {
                this.iconName = val.query.name
                this.tabs.forEach(ee=> {
                    if(ee.name == this.iconName) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
                this.getList()
            },
            clickTabs(name) {
                this.iconName = name
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
                this.getList()
            }
        }
    }
</script>
<style scoped>
.titlename1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}
.titlename2 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}
@media screen and (max-width:768px){
    .titlename1 {
        font-size: 18px;
    }
    .titlename2 {
        font-size: 13px;
    }
}
</style>