<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <div class="blog-area rn-section-gap">
            <div class="containermax flex-center" style="background: #FFFFFF;" v-if="tabs.length>0">
                <div class="flex-center" >
                    <!-- <div class="cursor-pointer">
                        <img src="@/assets/images/icons/up-left.png" alt="" style="width:10px;margin-right:40px">
                    </div> -->
                    <div  v-for="(item,index) in tabs" :key="index" @click="clickTabs(item.name)" >
                        <div :class="item.check?'text-18 cursor-pointer':'text-18 cursor-pointer c-000'" style="margin-right:20px">
                        {{item.name}}
                        <div class="current" v-if="item.check"></div>
                    </div>
                    </div>
                    <!-- <div class="cursor-pointer">
                        <img src="@/assets/images/icons/up-right.png" alt="" style="width:10px;margin-left:20px">
                    </div> -->
                </div>
            </div>
            <div class="container mt--20">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :subtitle="className"
                            :title="className"
                            description="100人工厂200人效益"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15  goods-width70 mt--15" v-for="(item,index) in brandList" :key='index'>
                    <div class="flex-between hover-box-small pl--10 pr--10 pt--20 pb--20" @click="todetail(item)">
                         <div class="flex-start1">
                            <div class="dop1"></div>
                            <div class="font-size18">{{item.title}}</div>
                        </div>
                        <div class="font-size18">
                            {{item.releaseDate}}
                        </div>
                    </div>
                    <div class="line1"></div>
                </div>
                <div class="flex-center mt--40">
                 <el-pagination class="pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="queryParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                    </el-pagination>
            </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../components/common/Layout";
    import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
    export default {
        name: 'softwareVideo',
        components: { SectionTitle,Layout},
        data() {
            return {
                className: '',
                total:0,
                queryParams: {
                    pageNum: 1,
                    pageSize: 10
                },
                iconName: '',
                tabs:[{
                    name:'服装大师软件',
                    check: true,

                }, {
                    name:'AURItech软件',
                    check: false,
                },{
                    name:'酷拍样板输出系统',
                    check: false,
                },{
                    name:'箱包大师软件',
                    check: false,
                },{
                    name:'超级排料软件',
                    check: false,
                },{
                    name:'模板工艺软件',
                    check: false,
                }
                ],
                brandList: [],
                type: '',
            }
        },
        watch: {
            $route: {
            handler: function(val, oldVal){
                this.changeRoute(val)
            },
            // 深度观察监听
            deep: true
        }
        },
        mounted(){
            this.changeRoute()
        },
        methods: {
            changeRoute() {
                this.iconName = ''
                this.tabs = []
                if(this.$route.query.model =='1') {
                    this.type = 'tec_softgoods_type'
                    this.className = '软件视频'
                    this.getclass()
                } else if(this.$route.query.model =='2') {
                    this.type = 'tec_hardgoods_type'
                    this.className = '硬件视频'
                    this.getclass()
                } else if(this.$route.query.model =='3') {
                    this.className = '相关下载'
                    this.getList()
                } else if(this.$route.query.model =='4') {
                    this.className = '常见问题解答'
                    this.getList()
                }
            },
            async getclass () {
                const res = await this.$http.get('/system/dict/data/type/'+this.type)
                let list = res.data.data
                this.tabs = []
                if(list&& list.length>0) {
                    list.forEach((ee,index)=>{
                        let pp = {}
                        pp.check = false
                        if(!this.iconName) {
                            if(index == 0) {
                                pp.check = true
                            } else {
                                pp.check = false
                            }
                            this.iconName = res.data.data[0].dictLabel
                        }
                        pp.name = ee.dictLabel
                        this.tabs.push(pp)
                    })
                }
                this.getList()
            },
            async getList () {
                const res = await this.$http.get('/siteWeb/technical/list?species='+this.className+'&isShow='+2+
                '&type='+this.iconName+'&pageNum='+this.queryParams.pageNum+'&pageSize='+this.queryParams.pageSize)
                this.brandList = res.data.rows
                this.total = res.data.total
                console.log(res)
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.queryParams.pageSize = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.queryParams.pageNum = val
                console.log(`当前页: ${val}`);
                this.getList()
            },
            todetail(item) {
                this.$router.push({name: 'softwareVideoDetail',query: {id: item.id,iconName:this.iconName,className:this.className}})
            },
            clickTabs(name) {
                this.iconName = name
                this.getList()
                this.tabs.forEach(ee=> {
                    if(ee.name == name) {
                        ee.check = true
                    } else {
                        ee.check = false
                    }
                })
            }
            
        }
    }
</script>
<style scoped>
.pagination>>>.el-pagination__total {
    font-size: 18px !important;
    color: #000 !important;
}
.pagination>>>.el-pager li {
    color: #000 !important;
    font-size: 18px !important;
}
.pagination>>>.el-pager li.active {
    color: #E60012 !important;
    font-size: 20px !important;
}
.pagination>>>.el-pagination__jump {
    font-size: 18px !important;
    color: #000 !important;
}
</style>