<template>
    <form class="contact-form-1" action="" @submit="sendEmail">
        <div class="title text-center mb--30" v-if="login"> 用户登录</div>
        <div class="title text-center mb--30" v-if="reg">新用户注册</div>
        <div class="title text-center mb--30" v-if="forgetPwd">忘记密码</div>
        <div class="link-hover text-center" v-if="login">
            <span class="mb--30" :style="show?'margin-right:20px;color:#E60012':'margin-right:20px'" @click="accountLogin">账号密码登录</span>
            <span class="footer-link link-hover mb--30" :style="show?'':'color:#E60012'" @click="telLogin">手机号验证码登录</span>
        </div>

        <div class="form-group">
            <input class="user" type="text" v-model="form.tel" placeholder="用户手机号码" required/>
        </div>
        <div class="form-group" v-if="show">
            <input class="user pwd" type="text" v-model="form.pwd" placeholder="输入登录密码" required />
        </div>
        <div class="form-group" v-if="!show || reg || forgetPwd">
            <div class="box">
                <input class="user code input" type="text" placeholder="短信验证码" required />
                <span class="text cursor" @click="getCode">发送验证码</span>
            </div>
        </div>
        <div class="form-group" v-if="reg || forgetPwd">
            <input class="user pwd" type="text" v-model="form.pwd" placeholder="设置6至20位登录密码" required/>
        </div>
        <div class="form-group" v-if="reg || forgetPwd">
            <input class="user pwd" type="text" v-model="form.newpwd"  placeholder="请再次输入登录密码" required/>
        </div>

        <div class="form-group" v-if="login">
            <div class="flex-between">
                <div v-if="show">
                    <input  class="form-check-input" type="checkbox" value="option" id="flexCheckIndeterminate">
                    <label class="form-check-label" for="flexCheckIndeterminate">
                        记住密码
                    </label>
                </div>
                <div v-if="!show">
                    <input class="form-check-input" type="checkbox" value="option1" id="flexCheckIndeterminate1">
                    <label class="form-check-label cursor" for="flexCheckIndeterminate1">
                        记住手机号
                    </label>
                </div>
                <div style="color: #E60012;" class="cursor" @click="showReg(1)">忘记密码?</div>
            </div>
        </div>
        <div class="form-group">
            <button class="btn-default btn-large" style="width:100%;background: #E60012;color:#fff" v-if="!forgetPwd">
               {{login?'登录':'立即注册'}}
            </button>
            <button class="btn-default btn-large" style="width:100%;background: #E60012;color:#fff" v-if="forgetPwd">
               确认密码
            </button>
        </div>
        <div class="form-group">
            <div class="reg cursor" @click="showReg(2)" v-if="login">新用户注册</div>
            <div class="reg cursor" @click="showLogin" v-if="reg || forgetPwd">用户登录</div>
        </div>
        <!-- <div class="form-group" v-html="showResult ? result : null"/> -->
    </form>
</template>

<script>
    export default {
        name: 'ContactForm',
        data() {
            return {
                forgetPwd:false,
                reg:false,
                login:true,
                form: {
                    tel: '',
                    pwd: ''
                },
                option1: false,
                option: false,
                show:true,
                showResult: false,
                result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
            }
        },
        methods: {
            accountLogin() {
                this.show = true
            },
            telLogin() {
                this.show = false
            },
            showReg(type) {
                this.show = false
                if(type == 1) {
                    this.forgetPwd = true
                    this.login = false
                    this.reg = false
                } else {
                    this.reg = true
                    this.login = false
                    this.forgetPwd = false
                }
            },
            showLogin() {
                this.reg = false
                this.forgetPwd = false
                this.login = true
            },
            getCode() {
                if(!this.form.tel) {
                }
            },
            sendEmail() {
                // alert('点击登录')
                this.showResult = true
                this.$router.push({name:'index'})
            },
        }
    }
</script>
<style scoped>
.user{
    background-image: url('../../../assets/images/icons/account.png');/*设置小图标*/
    background-size: 25px 25px;/*小图标的大小*/
    background-position: 5px 8px;/*小图标在input的位置*/
    background-repeat: no-repeat;/*背景小图标不重复*/
}
.pwd {
   background-image: url('../../../assets/images/icons/pwd.png');/*设置小图标*/ 
   background-size: 25px 25px;/*小图标的大小*/
}
.code {
   background-image: url('../../../assets/images/icons/code.png');/*设置小图标*/ 
   background-size: 26px 22px;/*小图标的大小*/
   background-position: 5px 15px;/*小图标在input的位置*/
}
.box {
    border: 2px solid rgba(80, 64, 64, 0.1);
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    /* width: 250px; */
}
.input {
    border: none;
    outline: none;
    width: 200px;
}
.text {
    border-left: 1px solid #a1a2a3;
    height: 15px;
    width: 120px;
    display: inline-block;
    line-height: 15px;
    padding-left: 15px;
    font-size: 12px;
    color: #E60012;
}
.cursor{
    cursor: pointer;
}
.title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}
.reg {
    
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E60012;
    text-align: right;
}
.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.router-link-exact-active:hover {
    color: #E60012 !important;
}
ul li a:hover {
    color: #E60012 !important;
}
</style>