<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="cneter-box pt--100" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="网上报修"
                        title="网上报修"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
            <div class="flex-cneter w40 web-name">
               请详细填写故障描述，奥瑞科技公司技术人员或客服会判断故障原因，并尽快与您联系。 我们不能保证您的留言肯定会被处理，如24小时内
            </div>
        </div>
        <div class="cneter-box pt--40 pb--60" style="background-color: #fff;">
            <div class="w80 plr-10">
                <div class="flex-start1">
                    <div class="title-icon"></div>
                    <div class="name-top">发送留言</div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">机器型号：</div>
                    </div>
                    <div class="form-input">
                        <input type="text" placeholder="机器型号或者机器编号请见机器背面售后">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">机器编号：</div>
                    </div>
                    <div class="form-input">
                        <input type="text" placeholder="机器型号或者机器编号请见机器背面售后">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">您的姓名：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">性别：</div>
                    </div>
                    <div class="form-input flex-start1" style=" box-shadow:none">
                      男 女
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">所在单位：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">您的地址 ：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">联系电话：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>

                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">电子邮件：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">QQ号码 ：</div>
                    </div>
                    <div class="form-input">
                        <input type="text">
                    </div>
                </div>
                <div class="flex-start1 pb1--40">
                    <div class="flex-start1">
                        <div class="dop"></div>
                        <div class="form-name">故障描述：</div>
                    </div>
                    <div class="form-input">
                        <input type="textarea">
                    </div>
                </div>
                
                <div class="flex-cneter mt--120">
                    <div class="commit-btn btn-text flex-cneter">
                        提交
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    export default {
        name: 'About',
        components: {SectionTitle,Layout},
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>
<style scoped>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #F44336 !important;
    color: #fff !important;
}
.nav-pills .nav-link {
    cursor: pointer;
    color: #000 !important;
    margin-right: 40px;
}
.text-tip {
    font-size: 32px;
    font-family: 'iFontszhounianti';
    font-weight: 400;
    color: #E10000;
}
.text-bt-tip {
    margin: 40px 0;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #000000;
}
</style>