<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <div class="cneter-box pt--40" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="行业资讯"
                        title="行业资讯"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
        </div>
        <div class="cneter-box pt--20 pb--60" style="background-color: #fff;">
            <div class="w80 plr-10 " v-for="(item,index) in news" :key="index">
               <div class="row mt--20 hover-box pt--40 pb--40" @click="toDetail()">
                    <div class="col-lg-2 date-box ">
                       <div class="flex-end100">
                            <div class="red-line"></div>
                        </div>
                        <div class="date pdlr-10">2022/04/</div>
                        <div class="date-day pdlr-10">24</div>
                    </div>
                    <div class="col-lg-4 text-center">
                        <img src="@/assets/images/demo/new1.png" alt="">
                    </div>
                    <div class="col-lg-6">
                        <div class="new-title text-center">“清凉”献给最可爱的人（之二） </div>
                        <div class="new-des pdlr-10">
                            4月22日，合肥市全员开展核酸检测，全市社区干部和医务人员奔赴一线开展工作，顶烈日，保平安。一直以来把“扶危济困，热心公益”作为办会宗旨。奥瑞和华祺等10家副会长以上单位一致同意捐资购买纯净水捐赠给核酸采样点的工作人员。
                        </div>
                    </div>
               </div>
               <div class="line1"></div>
            </div>


            <div class="flex-center mt--40">
                 <el-pagination class="pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="40">
                    </el-pagination>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    export default {
        name: 'About',
        components: {SectionTitle,Layout},
        data() {
            return {
                currentPage:1,
                news: [1,2,3,4,5,6,7,8,9]
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            toDetail() {
                this.$router.push({name:'newsDetail'})
            }
        }
    }
</script>
<style scoped>
.pagination>>>.el-pagination__total {
    font-size: 18px !important;
    color: #000 !important;
}
.pagination>>>.el-pager li {
    color: #000 !important;
    font-size: 18px !important;
}
.pagination>>>.el-pager li.active {
    color: #E60012 !important;
    font-size: 20px !important;
}
.pagination>>>.el-pagination__jump {
    font-size: 18px !important;
    color: #000 !important;
}
</style>