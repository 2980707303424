import axios from "axios";

// const  API_BASE_URL='http://192.168.31.93:10087' // 天佑
// const  API_BASE_URL='http://47.100.109.76:10087' // 测试
// const  API_BASE_URL = 'http://61.191.198.49:10087' // 正式


const  API_BASE_URL = 'https://www.cad361.com/api2' // 正式域名访问
const indexApi = {

/** 兑换商品列表 */
getadChangeFun(pageNum,pageSize) {
    return axios.get(`${API_BASE_URL}/ad/adGoods/list?`
    +'pageNum='+pageNum+'&pageSize='+pageSize);
},

/** 积分明细 */
getadFinishFun(userName) {
    return axios.get(`${API_BASE_URL}/ad/adFinish/noPageList?userName=`+userName,{
        headers:{
        'Authorization':localStorage.getItem("token")
        }
    });
},

/** 兑换记录 */
getChangeListFun(userName) {
    return axios.get(`${API_BASE_URL}/ad/adChange/noPageList?userNumber=`+userName,{
        headers:{
        'Authorization':localStorage.getItem("token")
        }
    });
},

/** 新增兑换 */
addChangeFun(param) {
    return axios.post(`${API_BASE_URL}/ad/adChange`,param,{
        headers:{
        'Authorization':localStorage.getItem("token")
        }
    })
},

/** 获取广告后台字典值 */
getCompanyDict(dictType) {
    return axios.get(`${API_BASE_URL}/system/dict/data/type/`+dictType);
},

/** 保存公司信息 */
companySave(param) {
    return axios.post(`${API_BASE_URL}/ad/adUser/editCompany`,param);
},


/** 登录 */
login(param) {
    return axios.post(`${API_BASE_URL}/ad/adUser/login`,param);
}
};

export default indexApi;