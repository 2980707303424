<template>
    <div class="copyright-area copyright-style-one variation-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-7 col-sm-12 col-12">
                    <!-- <Separator/> -->
                    <div class="copyright-left flex-between align-items-center" style=";flex-wrap: wrap;">
                      
                        <img src="@/assets/images/logo/logo-2x.png" alt="" style="width:192px">
                        <div class="link-hover">
                            <p class="copyright-text">
                            技术支持: 合肥奥瑞数控科技有限公司 | <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#fff;margin-left:10px" target="_blank" rel="noopener noreferrer">皖ICP备11003314号-9</a>
                            | <img src="@/assets/images/logo/gongan.png"/>
                            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34010302002474" style="color:#fff;margin-left:10px" target="_blank" rel="noopener noreferrer">皖公网安备 34010302002474号</a>
                        </p>
                            
                        </div>
                        
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-5 mt_sm--20">
                    <div class="copyright-center text-center">
                        <ul class="social-icon social-default color-lessdark justify-content-center">
                            <li><a href="https://www/facebook.com/" target="_blank"><Icon name="facebook"/></a></li>
                            <li><a href="https://www/twitter.com/" target="_blank"><Icon name="twitter"/></a></li>
                            <li><a href="https://www/instagram.com/" target="_blank"><Icon name="instagram"/></a></li>
                            <li><a href="https://www/linkedin.com/" target="_blank"><Icon name="linkedin"/></a></li>
                        </ul>
                    </div>
                </div> -->
                <!-- <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text">
                            技术支持: 安徽元栖智能科技 © {{ new Date().getFullYear() }} | 皖ICP备11003314号-9
                        </p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Separator from '../../../components/elements/separator/Separator'
    import Icon from '../../icon/Icon'

    export default {
        name: 'CopyrightTwo',
        components: {Icon,Separator}
    }
</script>