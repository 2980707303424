<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-750 bg_image"
            :style="{'background-image': `url(${require(`@/assets/images/contact/top-bac.png`)})`,'background-size': '100% 100%'}">
            <!-- <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="inner pt--80">
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                               我们用心倾听
                            </h1>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                               每一位用户的声音
                            </h1>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="cneter-box pt--20" style="background-color: #fff;">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="诚邀加盟"
                        title="诚邀加盟"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>
            <div class="flex-cneter w80 name-top">
                奥瑞科技2023年CAD/CAM渠道新区域招商启动
            </div>
            <div class="flex-start w80 name-des">
               奥瑞数控科技有限公司自2006年实施渠道加盟销售战略以来，已在全国设立60多家4S店及加盟商，并建立了长期稳定的合作关系，十年以来市场共建、互助共赢的管理模式和销售模式在国内CAD/CAM行业取得了辉煌的业绩！   
            </div>
            <div class="flex-start w80 name-des mt--40">
                随着近年来国内经济结构的调整与转型，服装产业也随之有了较大程度的转移与重新布局，为了进一步开发新生市场，公司将于2018年进一步开辟渠道区域，继续招募优秀渠道商，携手共同开拓新市场！只要您对区域市场有信心、有把握，有能力，就来加盟奥瑞科技营销体系吧！
            </div>


            <div class="flex-start w80 name-des mt--40 color-red1">
                奥瑞文化 / Aury Culture
            </div>
            <div class="flex-start w80 name-des">
                ● 理念：品质至上，行业先锋；互助互利，协作共赢。
            </div>
            <div class="flex-start w80 name-des">
                ● 产品：一元为主多元发展的市场战略，提供CAD/CAM系统产品及整厂解决方案。
            </div>
            <div class="flex-start w80 name-des">
                ● 平台：奥瑞智能化服装产业集群，为集群内企业提供整厂智能化改造，实现100人工厂200人效益！
            </div>
            <div class="flex-start w80 name-des">
                ● 管理：实现品质、营销、服务的标准化管理体系。
            </div>



            <div class="flex-start w80 name-des mt--40 color-red1">
                奥瑞坚持 / Ori Insisted
            </div>
            <div class="flex-start w80 name-des">
            ● 致力打造中国CAD/CAM行业最好的渠道商团队！
            </div>
            <div class="flex-start w80 name-des">
                ● 协助渠道商在管理、市场、客户开发等多方面给予强大的培训和最大的支持！
            </div>
            <div class="flex-start w80 name-des">
                
                ● 推动渠道商持续稳定的发展和创造更好的赚钱机会！
            </div>
            <div class="flex-start w80 name-des">
               ● 不断推进自身产品创新与升级，给予渠道商更多的发展空间！
            </div>


            <div class="flex-start w80 name-des mt--40 color-red1">
                加盟条件 / Conditions Of Joining
            </div>
            <div class="flex-start w80 name-des">
               ● 公司具有合法营业执照、个体经营执照及营业场所，具有良好的商业信誉和口碑。
            </div>
            <div class="flex-start w80 name-des">
               ● 公司有在服装CAD/CAM行业从事相关产品的代理、销售经营背景，拥有3人以上的成熟销售团队和销售模式，并取得相应业绩。
            </div>
            <div class="flex-start w80 name-des pb--40"> 
            ● 强烈认同奥瑞科技的企业文化，奉行客户第一，注重服务理念，抱有真诚而长远的合作态度
            </div>
        </div>

        <div class="cneter-box pt--40" style="background-color: #fff;">
            <div class="flex-between w80 pb--40">
                <div class="min-w500">
                    <div class="top-t">南方区总部营销总监</div>
                    <div class="top-b">周部长   15209852335</div>
                </div>
                <div class="min-w500">
                    <div class="top-t">北方区总部营销总监 </div>
                    <div class="top-b">宋部长 15056980352</div>
                </div>
            </div>

            <div class="flex-between w80 pb--40">
                <div class="min-w500">
                    <div class="top-t">山东区域营销总监</div>
                    <div class="top-b">曹部长13866119010</div>
                </div>
                <div class="min-w500">
                    <div class="top-t">全国吊挂、推广营销总监</div>
                    <div class="top-b">张总经理  13739294330</div>
                </div>
            </div>
        </div>
        <div class="cneter-box pt--40 pb--40">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="2023年奥瑞科技营销区域"
                        title="2023年奥瑞科技营销区域"
                        description="100人工厂200人效益"
                        data-aos="fade-up"
                    />
                </div>
            </div>

            <div class="flex-between w80" style="padding:0 30px">
                <div class="top-t" style="width:30%">负责区域</div>
                <div class="top-t" style="width:40%">联系方式 </div>
                <div class="top-t" style="width:30%">负责人</div>
            </div>
            <div class="flex-between w80" style="padding:0 30px" v-for="(item,index) in brandList" :key="index">
                <div class="top-b" style="width:30%">{{item.city}}</div>
                <div class="top-b" style="width:40%">{{item.tel}} </div>
                <div class="top-b" style="width:30%">{{item.name}}</div>
            </div>
        </div>


        

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                name: '关于奥瑞',
                tabs: [
                    {name: '关于奥瑞',check:true},
                    {name: '企业荣誉',check:false},
                    {name: '发展历程',check:false}
                ],
                brandList: [
                    {name: '胡慧椿',tel: '17611239975', city: '电商渠道'},
                    {name: '周海东',tel: '15209852335', city: '大客户'},

                    {name: '桑高林',tel: '18657167898', city: '浙江省、上海市'},
                    {name: '朱明风',tel: '13034053405', city: '广东省'},

                    {name: '张翠萍',tel: '18156503459', city: '广东省'},
                    {name: '田甜',tel: '18255163320', city: '江苏省'},

                    {name: '叶宁',tel: '15375516246', city: '江西省、福建省、河南省'},

                    {name: '王晴',tel: '15655177023', city: '湖北省，湖南省'},
                    {name: '曹雪琴',tel: '13866119010', city: '山东省、河北省'},
                   
                    {name: '宋殿友',tel: '15056980352', city: '安徽省'},
                    {name: '夏晓东',tel: '13470998737', city: '四川省、河南省'},
                    {name: '罗海',tel: '13956980969', city: '广东省、广西省、海南省'},
                    {name: '张艳',tel: '13739294330', city: '东北、西北、新疆省、广西省'},
                    {name: '罗晓波',tel: '18130085731', city: '国外市场'},
                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ],
                counterData: [
                    {
                        number: 199,
                        title: 'Happy Clients',
                    },
                    {
                        number: 575,
                        title: 'Employees',
                    },
                    {
                        number: 69,
                        title: 'Useful Programs',
                    },
                    {
                        number: 500,
                        title: 'Useful Programs',
                    }
                ],
                teamData: [
                    {
                        image: 'team-dark-01',
                        name: 'Sr Janen Sara',
                        designation: 'Sr Product Designer',
                        location: 'CO Miego, AD, USA',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-02',
                        name: 'Corporate Jane',
                        designation: 'Manager',
                        location: 'Bangladesh',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-03',
                        name: 'Jara Saraif',
                        designation: 'Software Developer',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                      {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    }
                ]
            }
        },
        methods: {
            tabsChoose(index) {
                this.tabs[index].check = true
                this.name = this.tabs[index].name
                this.tabs.forEach((e,cindex) =>{
                    if(index!==cindex) {
                        e.check = false
                    }
                })
            }
        }
    }
</script>
<style scoped>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #F44336 !important;
    color: #fff !important;
}
.nav-pills .nav-link {
    cursor: pointer;
    color: #000 !important;
    margin-right: 40px;
}
.text-tip {
    font-size: 32px;
    font-family: 'iFontszhounianti';
    font-weight: 400;
    color: #E10000;
}
.text-bt-tip {
    margin: 40px 0;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #000000;
}
</style>