import Vue from 'vue'
import VueRouter from 'vue-router'
// 大屏
import screenIndex from '../views/screen/index'
//用户协议
import UserAgreement from '@/components/UserAgreement';

// 我的
import login from '../views/home-pages/login'
import About from '../views/About'
import joinHands from '../views/joinHands'
import payType from '../views/payType'
import onlineRepair from '../views/onlineRepair'
import software from '../views/software'
import hardware from '../views/hardware'
import Startup from '../views/home-pages/index'
import index from '../views/home-pages/index'
import test from '../views/home-pages/test'
import softwareDeatil from '../views/softwareDeatil'
import buyOrder from '../views/buyOrder'
import newIndex from '../views/newIndex'
import newInfo from '../views/newInfo'
import newsDetail from '../views/newsDetail'
import serviceIndex from '../views/serviceIndex'
import softwareVideo from '../views/softwareVideo'
import softwareVideoDetail from '../views/softwareVideoDetail'
import hardwareVideo from '../views/hardwareVideo'
import hardwareVideoDetail from '../views/hardwareVideoDetail'
import download from '../views/download'
import downloadDetail from '../views/downloadDetail'
import answer from '../views/answer'
import answerDetail from '../views/answerDetail'
import techHd from '../views/techHd'
import ContactPage from '../views/ContactPage'

import exchange from '../views/exchange'






Vue.use(VueRouter)

const routes = [
    {
        path: '/user-agreement',
        name: 'UserAgreement',
        component: UserAgreement,
    },
    {
        path: '/',
        name: 'index',
        component: Startup,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            title: '登录',
        },
    },
    {
        path: '/test',
        name: 'test',
        component: test,
        meta: {
            title: '建设中',
        },
    },
    {
        path: '/onlineRepair',
        name: 'onlineRepair',
        component: onlineRepair,
        meta: {
            title: '网上报修',
        },
    },
    {
        path: '/hardware',
        name: 'hardware',
        component: hardware,
        meta: {
            title: '智能硬件',
        },
    },
    {
        path: '/software',
        name: 'software',
        component: software,
        meta: {
            title: '智能软件',
        },
    },
    {
        path: '/softwareDeatil',
        name: 'softwareDeatil',
        component: softwareDeatil,
        meta: {
            title: '详情',
        },
    },
    {
        path: '/buyOrder',
        name: 'buyOrder',
        component: buyOrder,
        meta: {
            title: '立即下单',
        },
    },
    {
        path: '/newIndex',
        name: 'newIndex',
        component: newIndex,
        meta: {
            title: '奥瑞动态',
        },
    },
    {
        path: '/newInfo',
        name: 'newInfo',
        component: newInfo,
        meta: {
            title: '行业资讯',
        },
    },
    {
        path: '/techHd',
        name: 'techHd',
        component: techHd,
        meta: {
            title: '智能后道',
        },
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        component: newsDetail,
        meta: {
            title: '新闻详情',
        },
    },

    {
        path: '/serviceIndex',
        name: 'serviceIndex',
        component: serviceIndex,
        meta: {
            title: '服务政策',
        },
    },

    {
        path: '/softwareVideo',
        name: 'softwareVideo',
        component: softwareVideo,
        meta: {
            title: '软件视频',
        },
    },
    {
        path: '/softwareVideoDetail',
        name: 'softwareVideoDetail',
        component: softwareVideoDetail,
        meta: {
            title: '软件视频详情',
        },
    },
    {
        path: '/hardwareVideo',
        name: 'hardwareVideo',
        component: hardwareVideo,
        meta: {
            title: '硬件视频',
        },
    },
    {
        path: '/hardwareVideoDetail',
        name: 'hardwareVideoDetail',
        component: hardwareVideoDetail,
        meta: {
            title: '硬件视频详情',
        },
    },
    {
        path: '/download',
        name: 'download',
        component: download,
        meta: {
            title: '相关下载',
        },
    },
    {
        path: '/downloadDetail',
        name: 'downloadDetail',
        component: downloadDetail,
        meta: {
            title: '相关下载详情',
        },
    },
    {
        path: '/answer',
        name: 'answer',
        component: answer,
        meta: {
            title: '常见问题解答',
        },
    },
    {
        path: '/answerDetail',
        name: 'answerDetail',
        component: answerDetail,
        meta: {
            title: '常见问题解答详情',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们',
        },
    },
    {
        path: '/joinHands',
        name: 'joinHands',
        component: joinHands,
        meta: {
            title: '诚邀合作',
        },
    },
    {
        path: '/payType',
        name: 'payType',
        component: payType,
        meta: {
            title: '汇款方式',
        },
    },
    {
        path: '/screenIndex',
        name: 'screenIndex',
        component: screenIndex,
        meta: {
            title: '裁床看板',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: '联系我们',
        },
    },
    {
        path: '/index',
        name: 'index',
        component: Startup,
        meta: {
            title: '首页',
        },
    },
    // {
    //     path: '/exchange',
    //     name: 'exchange',
    //     component: exchange,
    //     meta: {
    //         title: '兑换商品',
    //     },
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
